<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Формирование паспорта технических средств
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <SelectFilter
                    ref="refUnit"
                    v-model="unitId"
                    :options="unitsFiltered"
                    label="Объект"
                    clearable
                    class="cell-span-col-2 q-mb-sm"
                    optionLabel="name"
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="address"
                    extendedScope
                    hideBottomSpace
                    :rules="[val => val || 'Нужно выбрать объект']"
                />

                <q-select
                    ref="refSignerUnit"
                    v-model="signerUnitId"
                    outlined
                    dense
                    :options="signersUnit"
                    optionValue="id"
                    :optionLabel="emplFio"
                    mapOptions
                    emitValue
                    label="Подписант от объекта"
                    class="q-mb-sm"
                    hideBottomSpace
                    :rules="[(val) => val && val > 0 || 'Укажите подписанта']"
                >
                    <template #option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section>
                                <q-item-label>
                                    {{ scope.opt.surname }}
                                    {{ scope.opt.name }}
                                    {{ scope.opt.patronymic || "" }}
                                </q-item-label>
                                <q-item-label caption>
                                    {{ postTitle(scope.opt.post_id) }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
                <q-select
                    ref="refSignerIac"
                    v-model="signerIacId"
                    outlined
                    dense
                    :options="signersFilial"
                    optionValue="id"
                    :optionLabel="emplFio"
                    mapOptions
                    emitValue
                    label="Подписант от филиала"
                    class="q-mb-sm"
                    hideBottomSpace
                    :rules="[(val) => val && val > 0 || 'Укажите подписанта']"
                >
                    <template #option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section>
                                <q-item-label>
                                    {{ scope.opt.surname }}
                                    {{ scope.opt.name }}
                                    {{ scope.opt.patronymic || "" }}
                                </q-item-label>
                                <q-item-label caption>
                                    {{ postTitle(scope.opt.post_id) }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-select
                    v-model="selectedYear"
                    outlined
                    dense
                    :options="yearsList"
                    label="Год"
                    class="q-mb-sm"
                    hideBottomSpace
                    :rules="[(val) => val && val > 0 || 'Выберите год']"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import {
    RIGHTS_CONSTANTS,
} from "@/store/constants";
import { createDoc, saveDoc } from "@/helpers/createDoc";
import { getToolsForPassport } from "@/helpers/getDataForReports";

export default {
    name: "DialogPassportUnitTools",
    components: {
        SelectFilter,
    },
    setup() {
        const y = (new Date()).getFullYear() - 9;
        return {
            yearsList: Array.from({ length: 10 }, (v, i) => i + y),
        };
    },
    // emits: ["ok"],
    data() {
        return {
            unitId: null,
            signerUnitId: null,
            signerIacId: null,
            selectedYear: (new Date()).getFullYear(),
        };
    },
    computed: {
        ...mapGetters({
            unitsFull: "unitsFull",
            filials: "filials",
            currentUser: "currentUser",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            usersRoles: "usersRoles",
            rolesWithRightIds: "rolesWithRightIds",
            postTitle: "postTitle",
            users: "users",
            getUserFIOshort: "getUserFIOshort",
            getToolKindName: "getToolKindName",
            getManufacturerName: "getManufacturerName",
            getToolModelName: "getToolModelName",
        }),
        unitsFiltered() {
            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)
                ? this.unitsFull
                : this.unitsFull.filter((u) => this.currentUser.units.includes(u.id));
        },
        signersUnit() {
            const orgId = this.getOrgIdOfUnit(this.unitId);

            const signersIds = this.usersRoles
                .filter((ur) => this.rolesWithRightIds("RIGHT_SIGNING_DOCS_UNIT").includes(ur.role_id))
                .map((ur) => ur.user_id);

            const signers = this.users.filter((e) => (
                (signersIds.includes(e.id) && e.organization_id === orgId)
            ));

            return signers;
        },
        signersFilial() {
            const unit = this.unitsFull.find((u) => u.id === this.unitId);

            if (!unit) {
                return [];
            }

            const filial = this.filials.find((f) => f.region_id === unit.region_id);

            if (!filial) {
                return [];
            }

            const signersIds = this.usersRoles
                .filter((ur) => this.rolesWithRightIds("RIGHT_SIGNING_DOCS_IAC")
                    .includes(ur.role_id))
                .map((ur) => ur.user_id);

            const signers = this.users.filter((e) => (
                signersIds.includes(e.id) && e.organization_id === filial.id
            ));

            return signers;
        },
    },
    watch: {
        unitId() {
            this.signerUnitId = null;
            this.signerIacId = null;
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$refs.refUnit.validate();
            this.$refs.refSignerUnit.validate();
            this.$refs.refSignerIac.validate();

            if (
                this.$refs.refUnit.hasError ||
                this.$refs.refSignerUnit.hasError ||
                this.$refs.refSignerIac.hasError
            ) {
                return;
            }

            const unit = this.unitsFull.find((u) => u.id === this.unitId);
            const filial = this.filials.find((f) => f.region_id === unit.region_id);
            const signerUnit = this.users.find((u) => u.id === this.signerUnitId);
            const signerIac = this.users.find((u) => u.id === this.signerIacId);
            const outFileName = `ПС_ТС_КСА_РС_${unit.vncode}.docx`;

            const tools = await getToolsForPassport({
                year: this.selectedYear,
                unitId: this.unitId,
            });

            const items = [];

            for (let i = 0; i < tools.length; i++) {
                const tool = tools[i];

                items.push({
                    tool_title: [
                        this.getToolKindName(tool.tool_kind_id),
                        this.getManufacturerName(tool.manufacturer_id),
                        this.getToolModelName(tool.model_id),
                    ].join(" "),
                    tool_serial_number: tool.serial_number,
                    tool_guaranty_dt: tool.guaranty_dt
                        ? this.$dayjs(tool.guaranty_dt).format("DD.MM.YYYY")
                        : "",
                });
            }

            const replacement = {
                unit_code: unit.vncode,
                passport_year: this.selectedYear,
                current_date: this.$dayjs().format("DD.MM.YYYY"),
                unit_title: unit.fullname,
                unit_signer_post: this.postTitle(signerUnit.post_id),
                unit_signer_fio: this.getUserFIOshort(this.signerUnitId),
                filial_title: filial.fullname,
                filial_signer_post: this.postTitle(signerIac.post_id),
                filial_signer_fio: this.getUserFIOshort(this.signerIacId),
                items,
            };

            createDoc(
                "passport-unit-tools.docx",
                outFileName,
                replacement,
            )
                .then((repairreport) => {
                    saveDoc(repairreport);
                });

            this.hide();
        },
        emplFio(empl) {
            const postTitle = this.postTitle(empl.post_id);
            return empl && empl.surname
                ? `${empl.surname} ${empl.name} ${empl.patronymic || ""}
                    ${postTitle ? `(${postTitle})` : ""}`
                : null;
        },
    },
};
</script>
