import axios from "axios";
import {
    siteHost,
} from "@/store/constants";

export function getDataForReportTollsWaitingRepair(
    regionId,
    dateStart,
    dateFinsh,
) {
    return new Promise((resolve, reject) => {
        const filter = { regionId, dateStart, dateFinsh };
        axios.get(`${siteHost}/api/tools_waiting_repair/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRequestsFormular({
    unitId,
    date_beg = "01.01.2022",
    date_end = "31.12.2022",
}) {
    return new Promise((resolve, reject) => {
        const filter = {
            unit_id: unitId,
            date_beg,
            date_end,
        };

        axios.get(`${siteHost}/api/requests_formular/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRequestsEnclosure1({
    unitId,
    date_beg = `01.01.${(new Date()).getFullYear()}`,
    date_end = `31.12.${(new Date()).getFullYear()}`,
}) {
    return new Promise((resolve, reject) => {
        const filter = {
            unit_id: unitId,
            date_beg,
            date_end,
        };

        axios.get(`${siteHost}/api/requests_enclosure_1/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRequestsEnclosure2({
    unitId,
    date_beg = `01.01.${(new Date()).getFullYear()}`,
    date_end = `31.12.${(new Date()).getFullYear()}`,
}) {
    return new Promise((resolve, reject) => {
        const filter = {
            unit_id: unitId,
            date_beg,
            date_end,
        };

        axios.get(`${siteHost}/api/requests_enclosure_2/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRequestsForArchive({
    region_id,
    date_beg = "01.01.2022",
    date_end = "31.12.2022",
}) {
    return new Promise((resolve, reject) => {
        const filter = {
            region_id,
            date_beg,
            date_end,
        };

        axios.get(`${siteHost}/api/requests_for_archive/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRequestsRepairReport({
    region_id,
    date_beg = `01.01.${(new Date()).getFullYear()}`,
    date_end = `31.12.${(new Date()).getFullYear()}`,
}) {
    return new Promise((resolve, reject) => {
        const filter = {
            region_id,
            date_beg,
            date_end,
        };

        axios.get(`${siteHost}/api/repair_report/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRequestsRepairAllRegions({
    date_beg = `01.01.${(new Date()).getFullYear()}`,
    date_end = `31.12.${(new Date()).getFullYear()}`,
}) {
    return new Promise((resolve, reject) => {
        const filter = {
            date_beg,
            date_end,
        };

        axios.get(`${siteHost}/api/requests_all/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRequestsSoftAllRegions({
    date_beg = `01.01.${(new Date()).getFullYear()}`,
    date_end = `31.12.${(new Date()).getFullYear()}`,
}) {
    return new Promise((resolve, reject) => {
        const filter = {
            date_beg,
            date_end,
        };

        axios.get(`${siteHost}/api/requests_soft_all/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRequestsSoft({
    region_id,
    date_beg = `01.01.${(new Date()).getFullYear()}`,
    date_end = `31.12.${(new Date()).getFullYear()}`,
}) {
    return new Promise((resolve, reject) => {
        const filter = {
            region_id,
            date_beg,
            date_end,
        };

        axios.get(`${siteHost}/api/requests_soft/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function hotLineRegister(payload) {
    return new Promise((resolve, reject) => {
        axios.post(
            `${siteHost}/api/request_hot_line_register`,
            payload,
            {
                onDownloadProgress: (progressEvent) => {
                    console.log(progressEvent.currentTarget.response);
                },
            },
        )
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function hotLineClose(payload) {
    return new Promise((resolve, reject) => {
        axios.post(
            `${siteHost}/api/request_hot_line_close`,
            payload,
        )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function hotLineEmailRegister(payload) {
    return new Promise((resolve, reject) => {
        axios.post(
            `${siteHost}/api/request_hot_line_email_register`,
            payload,
        )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function hotLineEmailClose(payload) {
    return new Promise((resolve, reject) => {
        axios.post(
            `${siteHost}/api/request_hot_line_email_close`,
            payload,
        )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getToolsForPassport(payload) {
    return new Promise((resolve, reject) => {
        axios.get(
            `${siteHost}/api/tools_for_passport`,
            {
                params: {
                    ...payload,
                },
            },
        )
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getToolsWithSoftwareForPassport(payload) {
    return new Promise((resolve, reject) => {
        axios.get(
            `${siteHost}/api/tools_for_passport_with_sofware`,
            {
                params: {
                    ...payload,
                },
            },
        )
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getHardwareReport(payload) {
    return new Promise((resolve, reject) => {
        axios.get(
            `${siteHost}/api/tools_for_hw_report`,
            {
                params: {
                    ...payload,
                },
            },
        )
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
