import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        gasSoftRenwals: [],
    },
    getters: {
        gasSoftRenwals: (state) => state.gasSoftRenwals,
    },
    actions: {
        getGasSoftRenewalsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/gas_soft_renewals`)
                    .then((res) => {
                        commit("setGarSoftRenewals", res.data.renewals);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveGasSoftRenewal({ dispatch }, renewal) {
            const formData = new FormData();

            formData.append(
                "filesList",
                JSON.stringify(
                    renewal.gas_soft_renewals_files
                        .map((file) => ({
                            file_name: file.file_name,
                            mimetype: file.mimetype,
                            path: file.path || null,
                        })),
                ),
            );

            renewal.gas_soft_renewals_files.forEach((file) => {
                if (file.blob) {
                    formData.append("gasSoftRenewalFiles", file.blob);
                }
            });

            delete renewal.gas_soft_renewals_files;

            formData.append(
                "renewal",
                JSON.stringify(renewal),
            );

            const method = renewal.id ? "put" : "post";
            const url = renewal.id
                ? `${siteHost}/api/gas_soft_renewal/${renewal.id}`
                : `${siteHost}/api/gas_soft_renewal`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                )
                    .then(() => {
                        dispatch("getGasSoftRenewalsFromDB");
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deleteGasSoftRenewal({ dispatch }, renewalId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/gas_soft_renewal/${renewalId}`)
                    .then(() => {
                        dispatch("getGasSoftRenewalsFromDB");
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    mutations: {
        setGarSoftRenewals(state, renewals) {
            state.gasSoftRenwals = renewals;
        },
    },
};
