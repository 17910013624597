<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    {{ dialogTitle }}
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <div
                    class="text-h6"
                    v-html="dialogMessage"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    :label="labelOk"
                    color="primary"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    :label="labelCancel"
                    color="primary"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    name: "DialogConfirm",
    props: {
        dialogTitle: {
            type: String,
            default: "Внимание",
        },
        dialogMessage: {
            type: String,
            required: true,
        },
        labelOk: {
            type: String,
            default: "Удалить",
        },
        labelCancel: {
            type: String,
            default: "Отменить",
        },
    },
    emits: ["ok", "cancel"],
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$emit("ok");
            this.hide();
        },
        onCancelClick() {
            this.$emit("cancel");
            this.hide();
        },
    },
};
</script>
