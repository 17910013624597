<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="valueProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    showValue
                    size="5.5em"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Модуль формирования отчета по оборудованию №1
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    ref="refRegionId"
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="cell-span-col-3"
                    hideBottomSpace
                    errorMessage="Нужно выбрать"
                    :error="!regionId"
                    style="width: 80%; display: inline-flex"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import { createXls, saveXls } from "@/helpers/createXls";
import { getHardwareReport } from "@/helpers/getDataForReports";

export default {
    name: "DialogCreateHardwareReport1",
    components: {
        SelectFilter,
    },
    data() {
        return {
            regionId: null,
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            appSettings: "appSettings",
            currentUser: "currentUser",
            getRegionName: "getRegionName",
            getOrgProperty: "getOrgProperty",
            getUnitsOfOrg: "getUnitsOfOrg",
            regions: "regions",
            orgs: "orgs",

        }),
        orgsFiltered() {
            return this.regionId
                ? this.orgs.filter((o) => o.region_id === this.regionId)
                : this.orgs;
        },
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$refs.refRegionId.validate();
            if (this.$refs.refRegionId.hasError) {
                return;
            }

            this.showSpinner = true;
            this.valueProgress = 0;

            const hardwareByOrgItems = [];

            const organizations = this.orgsFiltered;
            for (let i = 0; i < organizations.length; i++) {
                this.valueProgress = Math.round((i / organizations.length) * 100);
                const unitsOfOrg = this.getUnitsOfOrg(organizations[i].id);
                let tools = [];
                for (let j = 0; j < unitsOfOrg.length; j++) {
                    tools = tools.concat(
                        (await getHardwareReport({
                            unitId: unitsOfOrg[j].id,
                        })),
                    );
                }

                const item = {
                    org_name: organizations[i].name,
                    srv_all: await this.getToolsCount(tools, 7, -1),  // id=7 - серверы
                    srv_5: await this.getToolsCount(tools, 7, 0),
                    srv_5_10: await this.getToolsCount(tools, 7, 5),
                    srv_10: await this.getToolsCount(tools, 7, 10),
                    arm_all: await this.getToolsCount(tools, 2, -1),  // id=2 - АРМ
                    arm_5: await this.getToolsCount(tools, 2, 0),
                    arm_5_10: await this.getToolsCount(tools, 2, 5),
                    arm_10: await this.getToolsCount(tools, 2, 10),
                    vks_all: await this.getToolsCount(tools, 18, -1),  // id=18 - ВКС
                    vks_5: await this.getToolsCount(tools, 18, 0),
                    vks_5_10: await this.getToolsCount(tools, 18, 5),
                    vks_10: await this.getToolsCount(tools, 18, 10),
                    print_all: await this.getToolsCount(tools, 4, -1),  // id=4 - принтеры
                    print_5: await this.getToolsCount(tools, 4, 0),
                    print_5_10: await this.getToolsCount(tools, 4, 5),
                    print_10: await this.getToolsCount(tools, 4, 10),
                    mfu_all: await this.getToolsCount(tools, 6, -1),  // id=6 - МФУ
                    mfu_5: await this.getToolsCount(tools, 6, 0),
                    mfu_5_10: await this.getToolsCount(tools, 6, 5),
                    mfu_10: await this.getToolsCount(tools, 6, 10),
                    shd_all: await this.getToolsCount(tools, 46, -1),  // id=46 - СХД
                    shd_5: await this.getToolsCount(tools, 46, 0),
                    shd_5_10: await this.getToolsCount(tools, 46, 5),
                    shd_10: await this.getToolsCount(tools, 46, 10),
                };
                hardwareByOrgItems.push(item);
            }

            let sum_srv_all = 0; let sum_srv_5 = 0; let sum_srv_5_10 = 0; let sum_srv_10 = 0;
            let sum_arm_all = 0; let sum_arm_5 = 0; let sum_arm_5_10 = 0; let sum_arm_10 = 0;
            let sum_vks_all = 0; let sum_vks_5 = 0; let sum_vks_5_10 = 0; let sum_vks_10 = 0;
            let sum_print_all = 0; let sum_print_5 = 0; let sum_print_5_10 = 0; let sum_print_10 = 0;
            let sum_mfu_all = 0; let sum_mfu_5 = 0; let sum_mfu_5_10 = 0; let sum_mfu_10 = 0;
            let sum_shd_all = 0; let sum_shd_5 = 0; let sum_shd_5_10 = 0; let sum_shd_10 = 0;
            for (let i = 0; i < hardwareByOrgItems.length; i++) {
                sum_srv_all += hardwareByOrgItems[i].srv_all;
                sum_srv_5 += hardwareByOrgItems[i].srv_5;
                sum_srv_5_10 += hardwareByOrgItems[i].srv_5_10;
                sum_srv_10 += hardwareByOrgItems[i].srv_10;
                sum_arm_all += hardwareByOrgItems[i].arm_all;
                sum_arm_5 += hardwareByOrgItems[i].arm_5;
                sum_arm_5_10 += hardwareByOrgItems[i].arm_5_10;
                sum_arm_10 += hardwareByOrgItems[i].arm_10;
                sum_vks_all += hardwareByOrgItems[i].vks_all;
                sum_vks_5 += hardwareByOrgItems[i].vks_5;
                sum_vks_5_10 += hardwareByOrgItems[i].vks_5_10;
                sum_vks_10 += hardwareByOrgItems[i].vks_10;
                sum_print_all += hardwareByOrgItems[i].print_all;
                sum_print_5 += hardwareByOrgItems[i].print_5;
                sum_print_5_10 += hardwareByOrgItems[i].print_5_10;
                sum_print_10 += hardwareByOrgItems[i].print_10;
                sum_mfu_all += hardwareByOrgItems[i].mfu_all;
                sum_mfu_5 += hardwareByOrgItems[i].mfu_5;
                sum_mfu_5_10 += hardwareByOrgItems[i].mfu_5_10;
                sum_mfu_10 += hardwareByOrgItems[i].mfu_10;
                sum_shd_all += hardwareByOrgItems[i].shd_all;
                sum_shd_5 += hardwareByOrgItems[i].shd_5;
                sum_shd_5_10 += hardwareByOrgItems[i].shd_5_10;
                sum_shd_10 += hardwareByOrgItems[i].shd_10;
            }

            const regionName = this.getRegionName(this.regionId);
            const outFileName = `Отчет по оборудованию №1 для региона ${regionName}.xlsx`;

            const replacement = {
                region_name: regionName,
                items: hardwareByOrgItems,
                sum_srv_all,
                sum_srv_5,
                sum_srv_5_10,
                sum_srv_10,
                sum_arm_all,
                sum_arm_5,
                sum_arm_5_10,
                sum_arm_10,
                sum_vks_all,
                sum_vks_5,
                sum_vks_5_10,
                sum_vks_10,
                sum_print_all,
                sum_print_5,
                sum_print_5_10,
                sum_print_10,
                sum_mfu_all,
                sum_mfu_5,
                sum_mfu_5_10,
                sum_mfu_10,
                sum_shd_all,
                sum_shd_5,
                sum_shd_5_10,
                sum_shd_10,
            };

            createXls(
                this.siteHost,
                "hardware-report-1.xlsx",
                outFileName,
                replacement,
            )
                .then((hardwarereport) => {
                    saveXls(hardwarereport);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getToolsCount(tools, tool_kind_id, years) {
            let count = 0;
            const date5 = new Date(new Date().setFullYear(new Date().getFullYear() - 5)).getTime();
            const date10 = new Date(new Date().setFullYear(new Date().getFullYear() - 10)).getTime();
            for (let i = 0; i < tools.length; i++) {
                if (tools[i].tool_kind_id === tool_kind_id) {
                    const toolDate = tools[i].work_in_dt ? new Date(tools[i].work_in_dt).getTime() : new Date("01/01/2000").getTime();
                    if (years === -1) {
                        count++;
                    }
                    if (years === 0 && toolDate > date5) {
                        count++;
                    }
                    if (years === 5 && toolDate > date10 && toolDate < date5) {
                        count++;
                    }
                    if (years === 10 && toolDate < date10) {
                        count++;
                    }
                }
            }
            return count;
        },
    },
};
</script>
