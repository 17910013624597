<template>
    <div class="tool-software-grid">
        <div class="cell cell-header cell-center">
            №
        </div>
        <div class="cell cell-header cell-center">
            Тип
        </div>
        <div class="cell cell-header cell-center">
            Наименование
        </div>
        <div class="cell cell-header cell-center">
            Версия
        </div>
        <div class="cell cell-header cell-center">
            Описание ПО
        </div>
        <div class="cell cell-header cell-center">
            <q-btn
                v-if="canWriteTool"
                round
                :icon="matEdit"
                size="0.7rem"
                color="primary"
                @click="editPrograms"
            />
        </div>

        <div
            v-for="(ts, index) in toolPrograms"
            :key="index"
            class="row-wrapper"
        >
            <div class="cell cell-center">
                {{ index + 1 }}
            </div>
            <div class="cell cell-center">
                {{ ts.type === PROGRAM_TYPES_VALUES.SOFT_COMMON ? "ОПО" : "СПО" }}
            </div>
            <div class="cell">
                {{ ts.title }}
            </div>
            <div class="cell cell-center">
                {{ ts.version }}
            </div>
            <div class="cell">
                {{ ts.description && ts.description.length > 100
                    ? `${ts.description.substring(0, 100)}...`
                    : ts.description || "" }}
            </div>
            <div class="cell cell-center">
                <q-btn
                    v-if="canWriteTool"
                    round
                    :icon="matDelete"
                    size="0.7rem"
                    @click="(e) => deleteItem(e, index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PROGRAM_TYPES_VALUES } from "@/store/constants";
import DialogEditToolSoftwareDialogEdit from "./DialogEditToolSoftwareDialogEdit.vue";

export default {
    name: "DialogEditToolSoftware",
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
        canWriteTool: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup() {
        return {
            PROGRAM_TYPES_VALUES,
        };
    },
    computed: {
        ...mapGetters({
            programs: "programs",
        }),
        toolPrograms() {
            const retVal = this.modelValue.map((ts) => {
                const program = this.programs.find((p) => p.id === ts.program_id);

                return program || {
                    type: PROGRAM_TYPES_VALUES.SOFT_COMMON,
                    title: "Неизвестная программа",
                    version: "-",
                    description: "-",
                };
            });

            return retVal || [];
        },
    },
    methods: {
        editPrograms() {
            if (!this.canWriteTool) {
                return;
            }

            this.$q.dialog({
                component: DialogEditToolSoftwareDialogEdit,
                componentProps: {
                    selectedPrograms: this.modelValue.map((p) => p.program_id),
                },
            })
                .onOk((ts) => {
                    this.$emit(
                        "update:modelValue",
                        ts.map((pi) => ({ program_id: pi })),
                    );
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();
            this.$emit(
                "update:modelValue",
                [
                    ...this.modelValue.slice(0, index),
                    ...this.modelValue.slice(index + 1),
                ],
            );
        },
    },
};
</script>
