<template>
    <q-card-section class="scroll q-pa-none">
        <div class="request-common-grid">
            <q-field
                outlined
                dense
                label="Номер"
                stackLabel
            >
                {{ request.number }}
            </q-field>
            <q-field
                outlined
                dense
                label="Дата"
                stackLabel
            >
                {{ $dayjs(request.request_date_add).format('DD.MM.YYYY') }}
            </q-field>
            <template v-if="request.id">
                <div />
                <q-input
                    v-model="hotLineNumber"
                    outlined
                    dense
                    label="Номер на ГЛ ГАС 'Правосудие'"
                    :readonly="!canBeEdited || !hotLineEditAllow"
                />
                <template v-if="canBeEdited && hotLineEditAllow">
                    <q-btn
                        :disable="!!hotLineNumber || !!request.hotline_email_register_sent"
                        label="Добавить на ГЛ"
                        color="primary"
                        noCaps
                        dense
                        :icon="matPlaylistAdd"
                        @click="hotLineRegisterL"
                    >
                        <q-tooltip
                            :delay="300"
                            anchor="bottom middle"
                            self="top right"
                            class="bg-amber text-body2 text-black shadow-4"
                        >
                            Зарегистрировать заявку на ГЛ ГАС 'Правосудие'
                        </q-tooltip>
                    </q-btn>
                    <q-btn
                        :disable="!hotLineNumber || !!hotLineDateClose || !!request.hotline_email_close_sent"
                        label="Закрыть на ГЛ"
                        color="primary"
                        noCaps
                        dense
                        :icon="matPlaylistAddCheck"
                        @click="hotLineCloseL"
                    >
                        <q-tooltip
                            :delay="300"
                            anchor="bottom middle"
                            self="top right"
                            class="bg-amber text-body2 text-black shadow-4"
                        >
                            {{ !!hotLineDateClose
                                ? `Заявка закрыты ${hotLineDateClose}`
                                : "Закрыть заявку на ГЛ ГАС 'Правосудие'" }}
                        </q-tooltip>
                    </q-btn>
                </template>
                <div
                    v-else
                    class="cell-span-col-2"
                />
            </template>

            <q-field
                outlined
                dense
                class="cell-span-col-6 "
                :disable="!editingAllowed"
            >
                <q-option-group
                    v-model="requestPerformerKind"
                    :options="requestPerformerKinds"
                />
            </q-field>

            <SelectFilter
                v-model="regionId"
                :options="regions"
                label="Регион"
                clearable
                class="cell-span-col-6"
                hideBottomSpace
                errorMessage="Нужно выбрать"
                :error="!regionId"
                :disable="!editingAllowed"
            />

            <SelectFilter
                v-model="reqUnitId"
                class="cell-span-col-6"
                label="Объект"
                :options="unitsFiltered"
                :extendedScope="true"
                scopeAttr1="vncode"
                scopeAttr2="name"
                scopeAttr3="address"
                hideBottomSpace
                errorMessage="Нужно выбрать"
                :error="requestValidations.validations.unit_id && !reqUnitId"
                :disable="!editingAllowed"
            />

            <q-field
                outlined
                dense
                class="cell-span-col-6"
                hideBottomSpace
                errorMessage="Нужно выбрать"
                :error="requestValidations.validations.contact_kind && !contactKind"
                :disable="!editingAllowed"
            >
                <q-option-group
                    v-model="contactKind"
                    :options="contactKinds"
                />
            </q-field>

            <q-input
                v-if="contactKind === CONTACT_KIND.STRANGER"
                v-model="contactUnknownName"
                label="Заявитель"
                outlined
                dense
                class="cell-span-col-3"
                :disable="!editingAllowed"
            />
            <q-input
                v-if="contactKind === CONTACT_KIND.STRANGER"
                v-model="contactUnknownEmail"
                label="Электронная почта заявителя"
                outlined
                dense
                class="cell-span-col-3"
                hideBottomSpace
                errorMessage="Укажите корректный email"
                :error="requestValidations.validations.contact_unknown_email"
                :disable="!editingAllowed"
            />

            <q-select
                v-if="contactKind === CONTACT_KIND.FROM_IAC"
                v-model="contactId"
                outlined
                dense
                :options="usersOfOrg"
                optionValue="id"
                :displayValue="emplFio"
                mapOptions
                emitValue
                label="Контактное лицо"
                class="cell-span-col-6"
                hideBottomSpace
                errorMessage="Укажите контактное лицо"
                :error="requestValidations.validations.contact_id && !contactId"
                :disable="usersOfOrg.length < 1 || !editingAllowed"
            >
                <template #option="scope">
                    <q-item v-bind="scope.itemProps">
                        <q-item-section>
                            <q-item-label>
                                {{ scope.opt.surname }}
                                {{ scope.opt.name }}
                                {{ scope.opt.patronymic || "" }}
                            </q-item-label>
                            <q-item-label caption>
                                {{ postTitle(scope.opt.post_id) }}
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>
            <!-- outer number and date -->
            <q-input
                v-model="reqNumberOut"
                outlined
                dense
                class="cell-span-col-3"
                label="Исходящий номер документа суда"
                :disable="!editingAllowed"
            />
            <PoeDatePicker
                v-model="reqDateOut"
                dateFormat="YMD"
                labelTitle="Дата документа суда"
                emptyDateAllowed
                :futureDateAllowed="false"
                classOuter="cell-span-col-3"
                :disable="!editingAllowed"
            />

            <q-btn
                label="Выбор оборудования"
                class="cell-span-col-3"
                color="primary"
                :disable="!reqUnitId || !editingAllowed"
                @click="toolStartSearch"
            />
            <q-btn
                label="Выбор типичной неисправности"
                class="cell-span-col-3"
                color="primary"
                :disable="!editingAllowed"
                @click="disrepairSearch"
            />
        </div>
    </q-card-section>

    <q-input
        v-model="reqDescription"
        outlined
        dense
        class="q-mb-sm"
        :label="reqKindId != REQUEST_KINDS_VALUES.LEARNING ? 'Описание неисправности (проблемы)' : 'Комментарий'"
        type="textarea"
        hideBottomSpace
        errorMessage="Введите описание"
        :error="requestValidations.validations.request_text && (!reqDescription || reqDescription.length < 5)"
        :disable="!editingAllowed"
    />
</template>

<script>
import { mapGetters } from "vuex";
import {
    REQUEST_STATUS_VALUES,
    REQUEST_KINDS_VALUES,
    GUARANTY_TYPES_VALUES,
    REPAIRS_TYPES,
    RIGHTS_CONSTANTS,
} from "@/store/constants";
import generateRequestProperty from "@/helpers/generateRequestProperty";

import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import DialogSearchTool from "@/components/Dialogs/DialogSearchTool.vue";
import DialogDisrepairSearch from "@/components/Dialogs/DialogDisrepairSearch.vue";
import {
    hotLineRegister,
    hotLineClose,
    hotLineEmailRegister,
    hotLineEmailClose,
} from "@/helpers/getDataForReports";

import {
    PERFORM_KIND,
    CONTACT_KIND,
} from "../../../../../poeso-server/app/config/constants";

export default {
    name: "DialogEditRequestCommon",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["setRequestSaving"],
    setup() {
        return {
            REQUEST_STATUS_VALUES,
            CONTACT_KIND,
            REQUEST_KINDS_VALUES,

            requestPerformerKinds: [
                {
                    value: PERFORM_KIND.SENIOR,
                    label: "Головному исполнителю (вопросы по эксплуатации подсистем)",
                },
                {
                    value: PERFORM_KIND.FILIAL,
                    label: "Филиал ИАЦ",
                },
            ],
            contactKinds: [
                {
                    value: CONTACT_KIND.FROM_IAC,
                    label: "Пользователь зарегистрирован в системе",
                },
                {
                    value: CONTACT_KIND.STRANGER,
                    label: "Пользователь не зарегистрирован в системе",
                },
            ],
        };
    },
    data() {
        return {
            regionIdProxy: null,
        };
    },
    computed: {
        ...mapGetters({
            requestValidations: "requestValidations",
            regions: "regions",
            unitsFull: "unitsFull",
            getOrgProperty: "getOrgProperty",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            users: "users",
            postTitle: "postTitle",
            request: "request",
            currentUser: "currentUser",
            getToolKindName: "getToolKindName",
            getManufacturerName: "getManufacturerName",
            getToolModelName: "getToolModelName",
            filials: "filials",
        }),
        editingAllowed() {
            return (
                this.canBeEdited &&
                this.request.request_status_id === REQUEST_STATUS_VALUES.WAITING_ENGINEER
            );
        },
        regionId: {
            get() {
                if (this.reqUnitId) {
                    const orgId = this.getOrgIdOfUnit(this.reqUnitId);
                    return this.getOrgProperty(orgId, "region_id");
                }

                return this.regionIdProxy;
            },
            set(v) {
                if (v !== this.regionIdProxy || v === null) {
                    this.regionIdProxy = v;
                    this.reqUnitId = null;
                }
            },
        },
        unitsFiltered() {
            const retVal = !!(this.currentUser.rightsObject.RIGHT_REQUEST_EDIT & RIGHTS_CONSTANTS.FULL)
                ? this.unitsFull
                : this.unitsFull.filter((u) => (
                    u.id === this.reqUnitId ||
                    this.currentUser.units.includes(u.id)
                ));

            return this.regionId
                ? retVal.filter((u) => u.region_id === this.regionId)
                : retVal;
        },
        hotLineEditAllow() {
            return !!(this.currentUser.rightsObject.RIGHT_HOTLINE_EDIT & RIGHTS_CONSTANTS.FULL);
        },
        usersOfOrg() {
            const orgId = this.getOrgIdOfUnit(this.reqUnitId);
            return this.users.filter((u) => u.is_inactive !== true && u.organization_id === orgId);
        },
        reqUnitId: {
            get() {
                return this.request.unit_id;
            },
            set(value) {
                this.setRequestField("unit_id", value);
                this.setRequestField("contact_id", null);
                this.setRequestField("tool_id", null);
            },
        },
        requestPerformerKind: generateRequestProperty("request_performer_kind"),
        contactKind: generateRequestProperty("contact_kind"),
        contactUnknownName: generateRequestProperty("contact_unknown_name"),
        contactUnknownEmail: generateRequestProperty("contact_unknown_email"),
        contactId: generateRequestProperty("contact_id"),
        hotLineDateClose: generateRequestProperty("hotlinegas_close_d"),
        emplFio() {
            const empl = this.usersOfOrg.find((e) => e.id === this.contactId);

            const postTitle = empl ? this.postTitle(empl.post_id) : "";
            return empl && empl.surname
                ? [
                    empl.surname,
                    empl.name,
                    empl.patronymic || "",
                    postTitle ? `(${postTitle})` : "",
                    empl.phone ? `, ${empl.phone}` : "",
                    empl.email ? `, ${empl.email}` : "",
                ].join(" ")
                : null;
        },
        reqKindId: {
            get() {
                return this.request.request_kind_id;
            },
            set(value) {
                this.setRequestField("request_kind_id", value);
                this.setRequestField("program_id", null);
            },
        },
        reqNumberOut: generateRequestProperty("number_out"),
        reqDateOut: {
            get() {
                return this.request.date_out_d || "";
            },
            set(value) {
                this.setRequestField("date_out_d", value);
            },
        },
        reqDescription: generateRequestProperty("request_text"),
        hotLineNumber: generateRequestProperty("hotlinegas_number"),
    },
    created() {
        if (!this.reqUnitId) {
            this.regionIdProxy = this.currentUser.region_id;
        }
    },
    methods: {
        async setRequestField(field, value) {
            await this.$store.dispatch(
                "setRequestField",
                {
                    field,
                    value,
                },
            );
        },
        hotLineRegisterL() {
            const orgId = this.getOrgIdOfUnit(this.reqUnitId);
            const hot_line_email = this.getOrgProperty(orgId, "hot_line_email");
            const hot_line_password = this.getOrgProperty(orgId, "hot_line_password");

            if (!hot_line_email || !hot_line_password) {
                hotLineEmailRegister(this.request)
                    .then(async () => {
                        await this.setRequestField(
                            "hotline_email_register_sent",
                            true,
                        );
                        this.$store.dispatch("saveRequest", true);
                        this.$q.notify({
                            type: "notify-success",
                            message: "Email отправлен",
                        });
                    });

                return;
            }

            this.$emit("setRequestSaving", true);
            hotLineRegister({
                hot_line_email,
                hot_line_password,
                request_id: this.request.id,
                request_kind: this.reqKindId,
                request_text: this.request.request_text,
            })
                .then((result) => {
                    const parts = result.split("_result_");

                    const res = parts.length === 2
                        ? JSON.parse(parts[1])
                        : {
                            status: 255,
                            data: {
                                message: "Ошибка",
                            },
                        };

                    if (res.status === 200) {
                        this.hotLineNumber = res.data.hot_line_number;

                        this.$q.notify({
                            type: "notify-success",
                            message: res.data.message,
                        });
                    }
                    else {
                        this.$q.notify({
                            type: "notify-failure",
                            message: res.data.message,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$q.notify({
                        type: "notify-failure",
                        message: "Ошибка регистрации",
                    });
                })
                .finally(() => {
                    this.$emit("setRequestSaving", false);
                });
        },
        hotLineCloseL() {
            const orgId = this.getOrgIdOfUnit(this.reqUnitId);
            const hot_line_email = this.getOrgProperty(orgId, "hot_line_email");
            const hot_line_password = this.getOrgProperty(orgId, "hot_line_password");
            const regionId = this.getOrgProperty(orgId, "region_id");

            if (!hot_line_email || !hot_line_password) {
                hotLineEmailClose({
                    hotlinegas_number: this.request.hotlinegas_number,
                })
                    .then(async () => {
                        await this.setRequestField(
                            "hotline_email_close_sent",
                            true,
                        );
                        this.$store.dispatch("saveRequest", true);
                        this.$q.notify({
                            type: "notify-success",
                            message: "Email отправлен",
                        });
                    });

                return;
            }

            let noteComment;

            if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                this.request.view_res_before === 1) {
                const repairType = REPAIRS_TYPES.find((rt) => (
                    rt.value === this.request.repair_type_id
                ));

                noteComment = repairType ? repairType.label : "";
            }
            else if (this.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                this.request.view_res_before === 3) {
                noteComment = "Выдано техническое заключение о неремонтопригодности";
            }
            else {
                const filial = this.filials.find((f) => f.region_id === regionId);

                noteComment = [
                    this.request.note,
                    [
                        "\nЗаявка выполнена силами",
                        filial.name.replace("Филиал", "филиала"),
                        filial.email ? `, email: ${filial.email}` : "",
                    ].join(" ").trim(),
                ].join("");
            }

            this.$emit("setRequestSaving", true);

            hotLineClose({
                hot_line_email,
                hot_line_password,
                hotLineNumber: this.hotLineNumber,
                request_close_text: noteComment,
            })
                .then((res) => {
                    if (res.status === 200) {
                        this.$q.notify({
                            type: "notify-success",
                            message: res.data.message,
                        });
                        this.$store.dispatch(
                            "setRequestField",
                            {
                                field: "hotlinegas_close_d",
                                value: new Date(),
                            },
                        );
                    }
                    else {
                        this.$q.notify({
                            type: "notify-failure",
                            message: `Ошибка: ${res.data.message}`,
                        });
                    }
                })
                .catch((err) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: `Ошибка: ${err.response.message}`,
                    });
                })
                .finally(() => {
                    this.$emit("setRequestSaving", false);
                });
        },
        toolStartSearch() {
            this.$store.dispatch(
                "setToolsFilter",
                {
                    toolsFilter: {
                        units_ids: [this.reqUnitId],
                    },
                    doUpdate: false,
                },
            );
            this.$q.dialog({
                component: DialogSearchTool,
                componentProps: {
                    toolsWithSoft: false,
                },
            })
                .onOk((tool) => {
                    this.reqDescription = [
                        this.reqDescription, this.reqDescription ? "\n" : "",
                        this.getToolKindName(tool.tool_kind_id), " ",
                        this.getManufacturerName(tool.manufacturer_id), " ",
                        this.getToolModelName(tool.model_id), ", ",
                        "серийный № ",
                        tool.serial_number, ", ",
                        "инвентарный № ",
                        tool.inventar_number,
                    ].join("");

                    if (!this.request.id || this.request.request_status_id === REQUEST_STATUS_VALUES.WAITING_ENGINEER) {
                        this.setRequestField("tool_id", tool.id);

                        if (!tool.guaranty_dt) {
                            this.request.repair_kind_id = GUARANTY_TYPES_VALUES.NOT_GUARANTY;
                        }
                        else {
                            const m = this.$dayjs(tool.guaranty_dt);
                            this.request.repair_kind_id = m.valueOf() >= Date.now()
                                ? GUARANTY_TYPES_VALUES.GUARANTY
                                : GUARANTY_TYPES_VALUES.NOT_GUARANTY;
                        }
                    }
                });
        },
        async disrepairSearch() {
            let tool = null;

            if (this.request.tool_id) {
                await this.$store.dispatch("setRequestLoading", true);
                tool = await this.$store.dispatch(
                    "getSingleToolFromDB",
                    this.request.tool_id,
                );
                await this.$store.dispatch("setRequestLoading", false);
            }

            this.$q.dialog({
                component: DialogDisrepairSearch,
                componentProps: {
                    toolsWithSoft: false,
                    toolKindId: tool ? tool.tool_kind_id : null,
                },
            })
                .onOk((description) => {
                    this.reqDescription = [
                        this.reqDescription, this.reqDescription ? "\n" : "",
                        description,
                    ].join("");
                });
        },
    },
};
</script>
