<template>
    <!-- <div
        v-if="appDevMode"
        class="content"
        style="padding: 8px; overflow-y: auto;"
    >
        <div>
            {{ currentUser }}
        </div>
        <div>Quasar: {{ $q.version }}</div>
    </div> -->
    <div class="content content__flex">
        <h1>Информационная система ФГБУ ИАЦ Судебного департамента (ИС ИАЦ)</h1>
        <h3>{{ IAC_VERSION }} ({{ IAC_DATE }})</h3>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { IAC_DATE } from "@/assets/version";
import { version } from "../../package.json";

export default {
    name: "About",
    setup() {
        return {
            IAC_VERSION: version,
            IAC_DATE,
        };
    },
    computed: {
        ...mapGetters({
            appDevMode: "appDevMode",
            currentUser: "currentUser",
        }),
    },
};
</script>
