import dayjs from "dayjs";
import {
    REQUEST_KINDS_VALUES,
    GUARANTY_TYPES_VALUES,
    REPAIRS_TYPES_VALUES,
    CONTACT_KIND,
} from "@/store/constants";

export default {
    state: {
    },
    getters: {
        requestValidations: (state, getters) => {
            // validations - start
            let validations = {};
            let tabName = "info";

            if (getters.request.id &&
                getters.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                (getters.request.repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_CONTRACT ||
                    getters.request.repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_MONEY) &&
                !getters.request.service_repair_id) {
                tabName = "tab2";
                validations = {
                    ...validations,
                    service_repair_id: true,
                };
            }

            if (![CONTACT_KIND.FROM_IAC, CONTACT_KIND.STRANGER].includes(getters.request.contact_kind)) {
                validations = {
                    ...validations,
                    contact_kind: true,
                };
            }

            if (!getters.request.unit_id) {
                validations = {
                    ...validations,
                    unit_id: true,
                };
            }

            if (getters.request.contact_kind === CONTACT_KIND.FROM_IAC && !getters.request.contact_id) {
                validations = {
                    ...validations,
                    contact_id: true,
                };
            }

            if (getters.request.contact_kind === CONTACT_KIND.STRANGER &&
                !/^[\w\-\.]+@([\w\-\.]+\.)+[\w-]{2,}$/.test(getters.request.contact_unknown_email.trim())) {
                validations = {
                    ...validations,
                    contact_unknown_email: true,
                };
            }

            if ((getters.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_SPECIAL ||
                getters.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_COMMON) &&
                !getters.request.program_id) {
                tabName = "tab2";
                validations = {
                    ...validations,
                    program_id: true,
                };
            }

            if ((getters.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_COMMON ||
                getters.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) &&
                !getters.request.tool_id) {
                tabName = "tab2";
                validations = {
                    ...validations,
                    tool_id: true,
                };
            }

            if (!getters.request.request_text || getters.request.request_text.length < 5) {
                validations = {
                    ...validations,
                    request_text: true,
                };
            }

            if (
                getters.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                getters.request.tool_in_d &&
                (
                    dayjs(getters.request.tool_in_d).format("YYYY-MM-DD") <
                    dayjs(getters.request.request_date_add).format("YYYY-MM-DD")
                )
            ) {
                tabName = "tab2";

                validations = {
                    ...validations,
                    tool_in_d: true,
                };
            }

            if (getters.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                getters.request.date_repair_d
            ) {
                tabName = "tab2";

                if (getters.request.tool_in_d &&
                    (
                        dayjs(getters.request.date_repair_d).format("YYYY-MM-DD") <
                        dayjs(getters.request.tool_in_d).format("YYYY-MM-DD")
                    )

                ) {
                    validations = {
                        ...validations,
                        date_repair_d: {
                            error: "Не может быть меньше даты поступления",
                        },
                    };
                }
                else if (
                    dayjs(getters.request.date_repair_d).format("YYYY-MM-DD") <
                    dayjs(getters.request.request_date_add).format("YYYY-MM-DD")
                ) {
                    validations = {
                        ...validations,
                        date_repair_d: {
                            error: "Не может быть меньше даты заявки",
                        },
                    };
                }
                else if (
                    dayjs(getters.request.date_repair_d).format("YYYY-MM-DD") >
                    dayjs(new Date()).format("YYYY-MM-DD")
                ) {
                    validations = {
                        ...validations,
                        date_repair_d: {
                            error: "Дата в будущем",
                        },
                    };
                }
            }

            if (
                getters.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                getters.request.tool_in_d &&
                getters.request.date_pass_d &&
                (
                    dayjs(getters.request.date_pass_d).format("YYYY-MM-DD") <
                    dayjs(getters.request.tool_in_d).format("YYYY-MM-DD")
                )
            ) {
                tabName = "tab2";

                validations = {
                    ...validations,
                    date_pass_d: true,
                };
            }

            if (
                getters.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                getters.request.date_return_d &&
                getters.request.date_pass_d &&
                (
                    dayjs(getters.request.date_return_d).format("YYYY-MM-DD") <
                    dayjs(getters.request.date_pass_d).format("YYYY-MM-DD")
                )
            ) {
                tabName = "tab2";

                validations = {
                    ...validations,
                    date_return_d: true,
                };
            }

            if (
                getters.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                getters.request.tool_out_d &&
                getters.request.tool_in_d &&
                (
                    dayjs(getters.request.tool_out_d).format("YYYY-MM-DD") <
                    dayjs(getters.request.tool_in_d).format("YYYY-MM-DD")
                )
            ) {
                tabName = "tab2";

                validations = {
                    ...validations,
                    tool_out_d: true,
                };
            }

            if (getters.request.request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                !!getters.request.repair_type_id &&
                (
                    (
                        getters.request.repair_kind_id === GUARANTY_TYPES_VALUES.GUARANTY &&
                        getters.request.repair_type_id !== REPAIRS_TYPES_VALUES.GUARANTY
                    ) ||
                    (
                        getters.request.repair_kind_id !== GUARANTY_TYPES_VALUES.GUARANTY &&
                        getters.request.repair_type_id === REPAIRS_TYPES_VALUES.GUARANTY
                    )
                )
            ) {
                tabName = "tab2";

                validations = {
                    ...validations,
                    repair_type_id: true,
                };
            }

            if (
                getters.request.result_d &&
                (
                    dayjs(getters.request.result_d).format("YYYY-MM-DD") <
                    dayjs(getters.request.request_date_add).format("YYYY-MM-DD")
                )
            ) {
                tabName = "tab2";

                validations = {
                    ...validations,
                    result_d: true,
                };
            }

            // validations - finish

            return {
                validations,
                tabName,
            };
        },
    },
    actions: {
    },
    mutations: {
    },
};
