<template>
    <div class="filter-programs">
        <PoeDatePicker
            :modelValue="dateBeg"
            labelTitle="Заявки С"
            emptyDateAllowed
            classOuter=""
            :doValidate="false"
            dateFormat="YMD"
            @update:modelValue="(v) => $emit('update:dateBeg', v)"
        />
        <PoeDatePicker
            :modelValue="dateEnd"
            labelTitle="Заявки ПО"
            emptyDateAllowed
            classOuter=""
            :doValidate="false"
            dateFormat="YMD"
            @update:modelValue="(v) => $emit('update:dateEnd', v)"
        />

        <q-input
            :modelValue="programTitle"
            outlined
            dense
            clearable
            label="Наименование ПО"
            @update:modelValue="(v) => $emit('update:programTitle', v)"
        />
        <q-select
            :modelValue="programId"
            outlined
            dense
            :options="programsFilter"
            optionValue="id"
            optionLabel="title"
            mapOptions
            emitValue
            label="Програмное обеспечение"
            clearable
            class="q-mb-sm"
            @update:modelValue="(v) => $emit('update:programId', v)"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PROGRAM_TYPES_VALUES } from "@/store/constants";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";

export default {
    name: "FilterOrganizations",
    components: {
        PoeDatePicker,
    },
    props: {
        dateBeg: {
            type: String,
            default: "",
        },
        dateEnd: {
            type: String,
            default: "",
        },
        programTitle: {
            type: String,
            default: "",
        },
        programId: {
            type: Number,
            default: 0,
        },
    },
    emits: [
        "update:dateBeg",
        "update:dateEnd",
        "update:programTitle",
        "update:programId",
    ],
    computed: {
        ...mapGetters({
            programs: "programs",
        }),
        programsFilter() {
            return this.programs.filter((p) => p.type === PROGRAM_TYPES_VALUES.SOFT_SPECIAL);
        },
    },
};
</script>
