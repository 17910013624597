<template>
    <div
        class="content content__flex-with-table"
        style="overflow-y: auto"
    >
        <div class="content-title">
            Обучающие материалы
            <q-space />
            <q-btn
                v-if="canBeEdited"
                label="Добавить раздел"
                :icon="matAdd"
                @click="addManualFolder"
            />
        </div>

        <q-splitter v-model="manualsSplitter">
            <template #before>
                <div style="overflow-y: auto; max-height: calc(100vh - 224px);">
                    <q-expansion-item
                        v-for="manualFolder in manuals"
                        :key="`f_${manualFolder.id}`"
                        headerClass="text-h6 bg-grey-2"
                        switchToggleSide
                    >
                        <template #header>
                            <div
                                class="flex"
                                style="width: 100%;"
                            >
                                {{ manualFolder.title }}
                                <q-space />
                                <q-btn
                                    v-if="canBeEdited"
                                    round
                                    :icon="matAdd"
                                    size="sm"
                                    @click="(e) => addManualItem(e, manualFolder.id)"
                                />
                                <q-btn
                                    v-if="canBeEdited"
                                    round
                                    :icon="matEdit"
                                    size="sm"
                                    @click="(e) => editManualFolder(e, manualFolder)"
                                />
                                <q-btn
                                    v-if="canBeEdited"
                                    round
                                    :icon="matDelete"
                                    size="sm"
                                    :disable="manualFolder.manual_items.length > 0"
                                    @click="(e) => deleteManualFolder(e, manualFolder.id)"
                                />
                            </div>
                        </template>

                        <q-list
                            bordered
                            separator
                        >
                            <q-item
                                v-for="manualItem in manualFolder.manual_items"
                                :key="`m_${manualItem.id}`"
                                v-ripple
                                clickable
                                @click="showManualItem(manualItem.id)"
                            >
                                <div
                                    class="flex"
                                    style="width: 100%; align-items: center;"
                                >
                                    {{ manualItem.title }}
                                    <q-space />
                                    <q-btn
                                        v-if="canBeEdited"
                                        round
                                        :icon="matEdit"
                                        size="sm"
                                        @click="(e) => editManualItem(e, manualItem)"
                                    />
                                    <q-btn
                                        v-if="canBeEdited"
                                        round
                                        :icon="matDelete"
                                        size="sm"
                                        @click="(e) => deleteManualItem(e, manualItem.id)"
                                    />
                                </div>
                            </q-item>
                        </q-list>
                    </q-expansion-item>
                </div>
            </template>
            <template #after>
                <ManualItem
                    :mItem="currentManualItem"
                    :canBeEdited="canBeEdited"
                />
            </template>
        </q-splitter>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import DialogEditSimple from "@/components/Dialogs/DialogEditSimple.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import DialogManualItem from "@/components/Dialogs/DialogManualItem.vue";
import ManualItem from "./ManualItem.vue";

export default {
    name: "Manuals",
    components: {
        ManualItem,
    },
    data() {
        return {
            manualsSplitter: 25,
            currentManualItem: {
                title: "",
                contents: "",
                manual_item_files: [],
            },
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            manuals: "manuals",
        }),
        canBeEdited() {
            return this.currentUser.rightsObject.RIGHT_MANUALS_EDIT === RIGHTS_CONSTANTS.FULL;
        },
        nodes() {
            return this.manuals.map((m) => ({
                label: m.title,
                id: `f_${m.id}`,
                header: "folder",
                childrenLength: m.manual_items.length,
                children: m.manual_items.map((mi) => ({
                    label: mi.title,
                    id: `m_${mi.id}`,
                })),
            }));
        },
        manualsItems() {
            return this.manuals
                .map((m) => m.manual_items)
                .flat();
        },
    },
    mounted() {
        this.$store.dispatch("getManualsFromDB");
    },
    methods: {
        addManualFolder() {
            this.$q.dialog({
                component: DialogEditSimple,
                componentProps: {
                    dialogTitle: "Создать раздел",
                    itemForEdit: "",
                },
            })
                .onOk((title) => {
                    this.$store.dispatch(
                        "saveManual",
                        {
                            id: null,
                            order: this.manuals.length,
                            title,
                        },
                    );
                });
        },
        editManualFolder(event, manualFolder) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogEditSimple,
                componentProps: {
                    dialogTitle: "Изменить раздел",
                    itemForEdit: manualFolder.title,
                },
            })
                .onOk((title) => {
                    this.$store.dispatch(
                        "saveManual",
                        {
                            id: manualFolder.id,
                            order: manualFolder.order,
                            title,
                        },
                    );
                });
        },
        deleteManualFolder(event, manualItemId) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    labelOk: "Удалить",
                    dialogMessage: "Удалить раздел руководств?",
                },
            })
                .onOk(() => {
                    this.$store.dispatch(
                        "deleteManual",
                        manualItemId,
                    );
                });
        },

        addManualItem(event, manualId) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogManualItem,
                componentProps: {
                    itemForEdit: {
                        id: null,
                        manual_id: manualId,
                        title: "",
                        contents: "",
                        order: this.manualsItems.length + 1,
                        manual_item_files: [],
                    },
                },
            })
                .onOk((manualItem) => {
                    this.$store.dispatch(
                        "saveManualItem",
                        manualItem,
                    );
                });
        },
        editManualItem(event, manualItem) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogManualItem,
                componentProps: {
                    itemForEdit: {
                        ...manualItem,
                    },
                },
            })
                .onOk((manualItemNew) => {
                    this.$store.dispatch(
                        "saveManualItem",
                        manualItemNew,
                    );
                });
        },
        deleteManualItem(event, manualItemId) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    labelOk: "Удалить",
                    dialogMessage: "Удалить обучающий материал?",
                },
            })
                .onOk(() => {
                    this.$store.dispatch(
                        "deleteManualItem",
                        manualItemId,
                    );
                });
        },
        showManualItem(manualItemId) {
            this.currentManualItem = this.manualsItems.find((mi) => mi.id === manualItemId);
        },
    },
};
</script>
