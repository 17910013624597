<template>
    <q-dialog
        ref="dialogEditToolSoftware"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Программное обеспечение
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-field
                    v-model="programType"
                    outlined
                    label="Тип программного обеспечения"
                    stackLabel
                    :rules="[val => !!val || 'Укажите тип программного обеспечения']"
                    hideBottomSpace
                    class="q-pb-sm"
                >
                    <q-option-group
                        v-model="programType"
                        :options="PROGRAM_TYPES"
                        color="primary"
                    />
                </q-field>

                <q-list
                    bordered
                    style="max-height: 500px; overflow-y: scroll;"
                >
                    <q-item
                        v-for="program in programsFiltered"
                        :key="program.id"
                    >
                        <q-item-section>
                            <q-item-label>
                                {{ program.title }}
                            </q-item-label>
                            <q-item-label caption>
                                {{ program.description && program.description.length > 200
                                    ? `${program.description.substring(0, 200)}...`
                                    : program.description
                                }}
                            </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-checkbox
                                :modelValue="programsIds.includes(program.id)"
                                @click="toggleProgram(program.id)"
                            />
                        </q-item-section>
                    </q-item>
                </q-list>

                <!-- <q-select
                    v-model="item.program_id"
                    outlined
                    dense
                    :options="programsFiltered"
                    optionValue="id"
                    mapOptions
                    emitValue
                    label="Программное обеспечение"
                    class="cell-span-col-6"
                    hideBottomSpace
                    :rules="[(val) => !!val || 'Укажите программное обеспечение']"
                >
                    <template #selected-item="scope">
                        {{ scope.opt.gas_sub_system ? `${scope.opt.gas_sub_system}, ` : "" }}
                        {{ scope.opt.title }}
                    </template>
<template #option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section>
                                <q-item-label>
                                    {{ scope.opt.gas_sub_system }}
                                    {{ scope.opt.title }}
                                </q-item-label>
                                <q-item-label caption>
                                    {{ scope.opt.description && scope.opt.description.length > 200
                                        ? `${scope.opt.description.substring(0, 200)}...`
                                        : scope.opt.description
                                    }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
</q-select> -->
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import {
    PROGRAM_TYPES_VALUES,
    PROGRAM_TYPES,
} from "@/store/constants";

export default {
    name: "DialogEditToolSoftwareDialogEdit",
    props: {
        selectedPrograms: {
            type: Array,
            required: true,
        },
    },
    emits: ["ok"],
    setup() {
        return {
            PROGRAM_TYPES,
        };
    },
    data() {
        return {
            programType: PROGRAM_TYPES_VALUES.SOFT_COMMON,
            programsIds: [],
        };
    },
    computed: {
        ...mapGetters({
            programs: "programs",
        }),
        programsFiltered() {
            return this.programs.filter((p) => (
                p.type === this.programType
            ));
        },
    },
    methods: {
        show() {
            this.programsIds = [...this.selectedPrograms];
            this.$refs.dialogEditToolSoftware.show();
        },
        hide() {
            this.$refs.dialogEditToolSoftware.hide();
        },
        onOkClick() {
            this.$emit("ok", this.programsIds);
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        toggleProgram(programId) {
            if (this.programsIds.includes(programId)) {
                this.programsIds = this.programsIds.filter((pi) => pi !== programId);
            }
            else {
                this.programsIds.push(programId);
            }
        },
    },
};
</script>
