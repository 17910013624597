<template>
    <div :class="[
        'row',
        marginBottom ? 'q-mb-sm' : '',
    ]">
        <q-select
            :ref="`refSigner`"
            v-model="signer"
            outlined
            dense
            :options="usersFiltered"
            optionValue="id"
            :optionLabel="emplFio"
            mapOptions
            emitValue
            :optionDisable="(opt) => signers.map((s) => s.id).includes(opt.id)"
            :label="labelSigner"
            class="col"
            hideBottomSpace
            :rules="[(val) => val && val > 0 || 'Укажите подписанта']"
        >
            <template #option="scope">
                <q-item v-bind="scope.itemProps">
                    <q-item-section>
                        <q-item-label>
                            {{ scope.opt.surname }}
                            {{ scope.opt.name }}
                            {{ scope.opt.patronymic || "" }}
                        </q-item-label>
                        <q-item-label caption>
                            {{ postTitle(scope.opt.post_id) }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-select>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "DialogSignersSigner",
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        signers: {
            type: Array,
            required: true,
        },
        rightSigningDocs: {
            type: String,
            required: true,
        },
        marginBottom: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["update:modelValue"],
    computed: {
        ...mapGetters({
            request: "request",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            users: "users",
            usersRoles: "usersRoles",
            rolesWithRightIds: "rolesWithRightIds",
            postTitle: "postTitle",
        }),
        usersFiltered() {
            let signers;
            const orgId = this.getOrgIdOfUnit(this.request.unit_id);
            const regionId = this.getOrgProperty(orgId, "region_id");

            switch (this.rightSigningDocs) {
                case "RIGHT_SIGNING_DOCS_UNIT": {
                    const signersIds = this.usersRoles
                        .filter((ur) => this.rolesWithRightIds("RIGHT_SIGNING_DOCS_UNIT")
                            .includes(ur.role_id))
                        .map((ur) => ur.user_id);

                    signers = this.users.filter((e) => (
                        (signersIds.includes(e.id) && e.organization_id === orgId)
                    ));
                    break;
                }
                case "RIGHT_SIGNING_DOCS_FINAL": {
                    const signersIds = this.usersRoles
                        .filter((ur) => this.rolesWithRightIds("RIGHT_SIGNING_DOCS_FINAL")
                            .includes(ur.role_id))
                        .map((ur) => ur.user_id);

                    signers = this.users.filter((e) => (
                        signersIds.includes(e.id) &&
                        this.getOrgProperty(e.organization_id, "region_id") === regionId
                    ));
                    break;
                }
                default: { // case RIGHT_SIGNING_DOCS_IAC:
                    const signersIds = this.usersRoles
                        .filter((ur) => this.rolesWithRightIds("RIGHT_SIGNING_DOCS_IAC")
                            .includes(ur.role_id))
                        .map((ur) => ur.user_id);

                    signers = this.users.filter((e) => (
                        signersIds.includes(e.id) &&
                        this.getOrgProperty(e.organization_id, "region_id") === regionId
                    ));
                }
            }
            return signers;
        },
        labelSigner() {
            switch (this.rightSigningDocs) {
                case "RIGHT_SIGNING_DOCS_IAC": {
                    return "Подписант от филиала";
                }
                case "RIGHT_SIGNING_DOCS_UNIT": {
                    return "Подписант от объекта";
                }
                case "RIGHT_SIGNING_DOCS_FINAL": {
                    return "Подписант от УСД";
                }
                default: {
                    return "Подписант";
                }
            }
        },
        signer: {
            get() {
                return this.modelValue.id;
            },
            set(signerId) {
                this.$emit(
                    "update:modelValue",
                    {
                        id: signerId,
                    },
                );
            },
        },
    },
    mounted() {
        if (this.usersFiltered.length === 1) {
            this.signer = this.usersFiltered[0].id;
        }
    },
    methods: {
        emplFio(empl) {
            const postTitle = this.postTitle(empl.post_id);
            return empl && empl.surname
                ? `${empl.surname} ${empl.name} ${empl.patronymic || ""}
                    ${postTitle ? `(${postTitle})` : ""}`
                : null;
        },
        validate() {
            this.$refs.refSigner.validate();
            return this.$refs.refSigner.hasError;
        },
    },
};
</script>
