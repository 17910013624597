import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        manuals: [],
    },
    getters: {
        manuals: (state) => state.manuals,
    },
    actions: {
        getManualsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/manuals`)
                    .then((res) => {
                        // console.log(res.data);
                        commit("setManuals", res.data.manuals);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveManual({ dispatch }, manual) {
            const method = manual.id === null
                ? "post"
                : "put";
            const url = manual.id === null
                ? `${siteHost}/api/manuals`
                : `${siteHost}/api/manuals/${manual.id}`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    manual,
                )
                    .then(() => {
                        // TODO: do not request again
                        dispatch("getManualsFromDB");
                        resolve("Сохранено");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteManual({ dispatch }, manualId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/manuals/${manualId}`)
                    .then(() => {
                        dispatch("getManualsFromDB");
                        resolve("Удалено");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        saveManualItem({ dispatch }, manualItem) {
            const formData = new FormData();

            formData.append(
                "filesList",
                JSON.stringify(
                    manualItem.manual_item_files
                        .map((file) => ({
                            file_name: file.file_name,
                            mimetype: file.mimetype,
                            path: file.path || null,
                        })),
                ),
            );

            for (const file of manualItem.manual_item_files) { // eslint-disable-line
                if (file.blob) {
                    formData.append("manualItemFiles", file.blob);
                }
            }

            delete manualItem.manual_item_files;

            formData.append(
                "manualItem",
                JSON.stringify(manualItem),
            );

            const method = manualItem.id === null
                ? "post"
                : "put";
            const url = manualItem.id === null
                ? `${siteHost}/api/manuals_items`
                : `${siteHost}/api/manuals_items/${manualItem.id}`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                )
                    .then(() => {
                        // TODO: do not request again
                        dispatch("getManualsFromDB");
                        resolve("Сохранено");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteManualItem({ dispatch }, manualItemId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/manuals_items/${manualItemId}`)
                    .then(() => {
                        // TODO: do not request again
                        dispatch("getManualsFromDB");
                        resolve("Удалено");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
    },
    mutations: {
        setManuals(state, manuals) {
            state.manuals = manuals;
        },
    },
};
