<template>
    <q-select
        v-model="result"
        outlined
        dense
        class="cell-span-col-6"
        label="Результат исполнения"
        clearable
        :options="performResults"
        emitValue
        mapOptions
        :disable="request.request_status_id != REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
        @update:modelValue="setPerformDate"
    />
    <q-select
        v-model="resultType"
        outlined
        dense
        class="cell-span-col-2"
        label="Вид исполнения"
        clearable
        :options="PERFORM_TYPES"
        emitValue
        mapOptions
        :disable="request.request_status_id != REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
    />
    <PoeDatePicker
        v-model="resultDate"
        dateFormat="YMD"
        outlined
        dense
        class="cell-span-col-2"
        label="Дата исполнения"
        emptyDateAllowed
        :minDate="new Date(request.request_date_add)"
        :disable="request.request_status_id != REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
    />
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="Время исполнения"
        :stackLabel="result_time !== null"
        color="dark"
        labelColor="grey-9"
        disable
    >
        <div>
            {{ result_time }}
        </div>
    </q-field>

    <q-input
        v-model="note"
        outlined
        dense
        class="cell-span-col-6"
        type="textarea"
        :inputStyle="{ resize: 'none' }"
        label="Выполненные работы"
        :disable="request.request_status_id != REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
    />

    <q-btn
        filled
        color="primary"
        class="cell-span-col-2"
        label="Сформировать Акт СО-3"
        :disable="denyActCo3 || !canBeEdited"
        @click="createActCo3"
    />
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="№ акта СО3"
        :stackLabel="!!co3Number"
    >
        <template #control>
            <div
                class="self-center full-width no-outline"
                tabindex="0"
            >
                {{ co3Number }}
            </div>
        </template>
    </q-field>
    <q-field
        outlined
        dense
        class="cell-span-col-2"
        label="Дата акта СО3"
        :stackLabel="!!co3Date"
    >
        <template #control>
            <div
                class="self-center full-width no-outline"
                tabindex="0"
            >
                {{ co3Date ? $dayjs(co3Date).format("DD.MM.YYYY") : "" }}
            </div>
        </template>
    </q-field>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import { createDoc } from "@/helpers/createDoc";
import {
    CONTACT_KIND,
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
    PERFORM_RESULTS_PO_SPECIAL,
    PERFORM_RESULTS_PO_COMMON,
    PERFORM_TYPES,
    DATE_LOCALE_2,
} from "@/store/constants";
import { dateYMDtoDMY, dateDiffYMD } from "@/helpers/helpers";
import DialogSigners from "@/components/Dialogs/DialogSigners/DialogSigners.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import generateRequestProperty from "@/helpers/generateRequestProperty";

export default {
    name: "DialogEditRequestMovingSoft",
    components: {
        PoeDatePicker,
    },
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            REQUEST_STATUS_VALUES,
            PERFORM_TYPES,
            dateYMDtoDMY,
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
            currentUser: "currentUser",
            requestDocs: "requestDocs",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getUserFIO: "getUserFIO",
            getUserFIOshort: "getUserFIOshort",
            getUserPostTitle: "getUserPostTitle",
            getUserDueOrder: "getUserDueOrder",
            unitAddress: "unitAddress",
            servicesRepairs: "servicesRepairs",
            getSignersCountByOrgKind: "getSignersCountByOrgKind",
            getKsaTitle: "getKsaTitle",
            users: "users",
            postTitle: "postTitle",
        }),
        performResults() {
            return this.request.request_kind_id === REQUEST_KINDS_VALUES.SOFT_SPECIAL
                ? PERFORM_RESULTS_PO_SPECIAL
                : PERFORM_RESULTS_PO_COMMON;
        },
        result: generateRequestProperty("result"),
        resultType: generateRequestProperty("result_type"),
        resultDate: generateRequestProperty("result_d"),
        note: generateRequestProperty("note"),
        co3Number: generateRequestProperty("co_3_number"),
        co3Date: generateRequestProperty("co_3_d"),
        denyActCo3() {
            return this.request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMING ||
                !this.request.result ||
                !this.request.note ||
                this.request.note.length < 4;
        },
        docsCount() {
            return this.requestDocs.length;
        },
        vncode() {
            return this.getOrgProperty(
                this.getOrgIdOfUnit(this.request.unit_id),
                "vncode",
            );
        },
        signersCountByOrgKind() {
            return this.getSignersCountByOrgKind(this.request.unit_id);
        },
        requestYear() {
            return new Date(this.request.request_date_add).getFullYear();
        },
        fileName() {
            return `${this.vncode}_actCO3_${this.request.number}_${this.requestYear}.docx`;
        },
        serviceCenter() {
            const sr = this.servicesRepairs.find((s) => (
                s.region_id === this.currentUser.region_id &&
                s.service_type === 1
            ));

            return sr
                ? `${sr.title}, ${sr.address}, ${sr.chief_fio}, ${sr.telephone}`
                : "";
        },
        requestContact() {
            if (this.request.contact_kind === CONTACT_KIND.STRANGER) {
                return this.request.contact_unknown_name;
            }

            const empl = this.users.find((e) => e.id === this.request.contact_id);
            const postTitle = empl ? this.postTitle(empl.post_id) : "";

            return empl && empl.surname
                ? [
                    empl.surname,
                    empl.name,
                    empl.patronymic || "",
                    postTitle ? `(${postTitle})` : "",
                    empl.phone ? `, ${empl.phone}` : "",
                    empl.email ? `, ${empl.email}` : "",
                ].join(" ")
                : "";
        },
        commentRules() {
            return this.result
                ? this.note && this.note.length > 3
                : true;
        },
        result_time() {
            return dateDiffYMD(this.request.request_date_add, this.resultDate);
        },
    },
    watch: {
        docsCount() {
            if (!this.actIsPresent()) {
                this.co3Number = null;
                this.co3Date = null;
            }
        },
    },
    mounted() {
        if (!this.actIsPresent()) {
            this.co3Number = null;
            this.co3Date = null;
        }
    },
    methods: {
        actIsPresent() {
            return this.requestDocs
                .map((rd) => rd.file_name)
                .includes(this.fileName);
        },
        setPerformDate() {
            this.resultDate = date.formatDate(Date.now(), "YYYY-MM-DD HH:mm:ss");
        },
        createActCo3() {
            if (!this.request.hotlinegas_number) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Создание акта невозможно, заполние поле 'Номер на \"ГЛ ГАС ПРАВОСУДИЕ\"'",
                });
                return;
            }

            if (this.actIsPresent()) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Акт уже сформирован.<br>Перейдите в раздел 'Документы'",
                });
                return;
            }

            this.$q.dialog({
                component: DialogSigners,
                componentProps: {
                    signersCountByOrgKind: this.signersCountByOrgKind,
                    showDocDate: true,
                },
            })
                .onOk(({
                    actDate,
                    signers,
                    signType,
                }) => {
                    const requestDate = date.formatDate(
                        new Date(this.request.request_date_add),
                        "«DD» MMMM YYYY г.",
                        DATE_LOCALE_2,
                    );
                    const dateDocLong = date.formatDate(
                        date.extractDate(actDate, "DD.MM.YYYY"),
                        "«DD» MMMM YYYY г.",
                        DATE_LOCALE_2,
                    );

                    this.co3Number = `ВР-${this.vncode}-${this.request.number}/${this.requestYear}`;
                    this.co3Date = date.extractDate(actDate, "DD.MM.YYYY");

                    createDoc(
                        signers[2].id ? "act-co-3.docx" : "act-co-3-2.docx",
                        this.fileName,
                        {
                            act_number: this.co3Number,
                            act_date: dateDocLong,
                            act_org_ksa_title: this.getKsaTitle(this.request.unit_id),
                            act_org_title: this.getOrgProperty(
                                this.getOrgIdOfUnit(this.request.unit_id),
                                "name",
                            ),
                            act_org_address: this.unitAddress(this.request.unit_id),
                            act_org_vncode: this.vncode,
                            signer_iac_fio_full: this.getUserFIO(signers[0].id),
                            signer_iac_post: this.getUserPostTitle(signers[0].id),
                            signer_iac_dueorder: this.getUserDueOrder(signers[0].id) || signers[0].dueOrder,
                            signer_iac_fio_short: this.getUserFIOshort(signers[0].id),
                            signer_unit_post: this.getUserPostTitle(signers[1].id),
                            signer_unit_dueorder: this.getUserDueOrder(signers[1].id) || signers[1].dueOrder,
                            signer_unit_fio_short: this.getUserFIOshort(signers[1].id),
                            signer_final_fio_full: this.getUserFIO(signers[2].id) || this.getUserFIO(signers[1].id),
                            signer_final_post: this.getUserPostTitle(signers[2].id) || this.getUserPostTitle(signers[1].id),
                            signer_final_dueorder: this.getUserDueOrder(signers[2].id) ||
                                signers[2].dueOrder ||
                                this.getUserDueOrder(signers[1].id) ||
                                signers[1].dueOrder,
                            signer_final_fio_short: this.getUserFIOshort(signers[2].id) || this.getUserFIOshort(signers[1].id),
                            request_date: requestDate,
                            request_number: this.request.hotlinegas_number,
                            request_contact: this.requestContact,
                            request_text: this.request.note,
                            service_center: this.serviceCenter,
                        },
                    )
                        .then((fileDoc) => {
                            this.$store.dispatch(
                                "addRequestDoc",
                                {
                                    file_name: fileDoc.name,
                                    mimetype: fileDoc.type,
                                    section: "requestDocs",
                                    blob: fileDoc,
                                    signers_count: signers[2].id ? 3 : 2,
                                    signer_iac_id: signers[0].id,
                                    signer_unit_id: signers[1].id,
                                    signer_final_id: signers[2].id,
                                    sign_type: signType,
                                },
                            );
                            this.$q.notify({
                                type: "notify-success",
                                message: "Акт сформирован.<br>Помещен в раздел 'Документы'",
                            });
                        });
                });
        },
    },
};
</script>
