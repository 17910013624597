<template>
    <div
        class="row-wrapper"
        :style="!canBeEdited ? 'cursor: default;' : ''"
        @click="canBeEdited ? $emit('startEdit', index) : null"
    >
        <div class="cell cell-center">
            {{ index + 1 }}
        </div>
        <div class="cell cell-center">
            {{ $dayjs(soft.date).format("DD.MM.YYYY") }}
        </div>
        <div class="cell">
            {{ soft.title }}
        </div>
        <div class="cell">
            {{ soft.description }}
        </div>
        <div class="cell">
            {{ programTitle }}
        </div>
        <div class="cell cell-column">
            <div
                v-for="(file, indexF) in soft.gas_soft_renewals_files"
                :key="indexF"
                class="soft-file-link"
                @click="(e) => saveFile(e, file)"
            >
                {{ file.file_name }}
            </div>
        </div>
        <div class="cell cell-center">
            <q-btn
                v-if="canBeEdited"
                round
                :icon="matDelete"
                size="sm"
                @click="deleteRenewal"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

export default {
    name: "GasSoftRenewalRow",
    props: {
        index: {
            type: Number,
            required: true,
        },
        soft: {
            type: Object,
            required: true,
        },
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["startEdit"],
    computed: {
        ...mapGetters({
            programs: "programs",
        }),
        programTitle() {
            const program = this.programs.find((p) => p.id === this.soft.program_id);

            return program ? program.title : "";
        },
    },
    methods: {
        deleteRenewal(event) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить обновление ПО ГАС 'Правосудие' ${this.soft.title}?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteGasSoftRenewal", this.soft.id);
                });
        },
        saveFile(event, file) {
            event.stopPropagation();

            saveAs(`${this.siteHost}/${file.path}`, file.file_name);
        },
    },
};
</script>
