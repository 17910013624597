<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Комплекты оборудования
            ({{ toolsSetsCount }})
            <q-space />
            <FilterToolsSets />
            <!-- <q-btn
                round
                :icon="matCreate"
                @click="searchToolsSet"
            /> -->
        </div>
        <div class="pagination">
            <q-pagination
                v-model="currentPage"
                color="blue-grey-7"
                :max="toolsSetsPagesCount"
                :maxPages="8"
                :boundaryNumbers="true"
            />
        </div>
        <div class="content-body tools-sets-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Регион
            </div>
            <div class="cell cell-header cell-center">
                Объект
            </div>
            <div class="cell cell-header cell-center">
                Вид комплекта
            </div>
            <!-- <div class="cell cell-header cell-center">
                Наименование комплекта
            </div> -->
            <div class="cell cell-header cell-center">
                Состав комплекта
            </div>
            <div class="cell cell-header cell-center">
                Инвентарный номер
            </div>
            <div class="cell cell-header cell-center">
                Ввод в эксплуатацию
            </div>
            <div class="cell cell-header cell-center">
                Срок использования
            </div>
            <div class="cell cell-header cell-center" />
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="editItem(null)"
                />
            </div>
            <!-- data -->
            <ToolsSetsRow
                v-for="(toolsSet, index) in toolsSets"
                :key="index"
                :index="index"
                :currentPage="currentPage"
                :toolsSet="toolsSet"
                @editItem="editItem"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogEditToolsSet from "@/components/Dialogs/DialogEditToolsSet/DialogEditToolsSet.vue";
import FilterToolsSets from "@/components/Filters/FilterToolsSets.vue";

import ToolsSetsRow from "./ToolsSetsRow.vue";

export default {
    name: "ToolsSets",
    components: {
        ToolsSetsRow,
        FilterToolsSets,
    },
    computed: {
        ...mapGetters({
            toolsSets: "toolsSets",
            toolsSetsCount: "toolsSetsCount",
            // toolsSetName: "toolsSetName",
            unitsFull: "unitsFull",
            toolsSetsPagesCount: "toolsSetsPagesCount",
            toolsSetsPageNumber: "toolsSetsPageNumber",
        }),
        currentPage: {
            get() {
                return this.toolsSetsPageNumber;
            },
            set(value) {
                this.$store.dispatch("setToolsSetsPageNumber", value);
            },
        },
    },
    mounted() {
        this.$store.dispatch("setToolsSetsPageNumber", 1);
    },
    methods: {
        editItem(index, bCreateNew = false) {
            const sel = window.getSelection();
            if (sel.type === "Range") {
                return;
            }

            let itemForEdit = {};

            if (index !== null) {
                itemForEdit = { ...this.toolsSets[index] };

                // if (bCreateNew) {
                //     delete itemForEdit.id;
                // }
            }

            this.$q.dialog({
                component: DialogEditToolsSet,
                componentProps: {
                    itemForEdit,
                    bCreateNew,
                },
            });
        },
        searchToolsSet() {
            // for updatding tools sets manually
            this.$store.dispatch("toolsSetsSearch");
        },
    },
};
</script>
