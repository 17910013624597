<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 1050px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <div class="text-h6 dialog-title">
                Модель учетной единицы
            </div>
            <div class="row">
                <div class="col-4 image-container">
                    <!-- pictures -->
                    <q-carousel
                        v-model="slide"
                        transitionPrev="slide-right"
                        transitionNext="slide-left"
                        animated
                        controlColor="primary"
                        navigation
                        arrows
                        infinite
                        :padding="false"
                        height="100%"
                    >
                        <q-carousel-slide
                            v-for="(picture, index) in filesListPictures"
                            :key="index"
                            :name="index + 1"
                        >
                            <q-img
                                :src="siteHost + '/' + picture.path"
                                spinnerColor="white"
                                style="width: 100%"
                            />
                        </q-carousel-slide>
                    </q-carousel>
                </div>
                <div class="col-8">
                    <q-tabs
                        :modelValue="tabCurrent"
                        dense
                        activeColor="primary"
                        indicatorColor="primary"
                        align="justify"
                        @update:modelValue="tabChange"
                    >
                        <q-tab
                            name="info"
                            label="Общее"
                        />
                        <q-tab
                            name="details"
                            label="Характеристики"
                        />
                        <q-tab
                            name="docs"
                            label="Документы"
                        />
                        <q-tab
                            name="pictures"
                            label="Изображения"
                        />
                    </q-tabs>

                    <q-separator />

                    <q-tab-panels
                        v-model="tabCurrent"
                        animated
                    >
                        <q-tab-panel name="info">
                            <!-- common info -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <q-select
                                    ref="refToolKind"
                                    v-model="toolModel.tool_kind_id"
                                    outlined
                                    dense
                                    :options="toolsKinds"
                                    optionValue="id"
                                    optionLabel="title"
                                    mapOptions
                                    emitValue
                                    label="Вид оборудования"
                                    class="q-mb-sm"
                                    clearable
                                    hideBottomSpace
                                    :rules="[val => val && val > 0 || 'Укажите вид оборудования']"
                                />
                                <q-select
                                    ref="refManufacturer"
                                    v-model="toolModel.manufacturer_id"
                                    outlined
                                    dense
                                    :options="manufacturers"
                                    optionValue="id"
                                    optionLabel="title"
                                    mapOptions
                                    emitValue
                                    label="Производитель"
                                    class="q-mb-sm"
                                    clearable
                                    hideBottomSpace
                                    :rules="[val => val && val > 0 || 'Укажите производителя оборудования']"
                                />
                                <q-input
                                    ref="refModel"
                                    outlined
                                    dense
                                    :modelValue="(toolModel.model && !!toolModel.model.trim())
                                        ? toolModel.model
                                        : null"
                                    class="q-mb-sm"
                                    label="Наименование"
                                    hideBottomSpace
                                    :rules="[val => val && val.length > 0 || 'Укажите наименование оборудования']"
                                    @update:modelValue="(e) => toolModel.model = e"
                                />
                                <q-checkbox
                                    v-model="toolModel.is_verified"
                                    label="Проверка оборудования выполнена"
                                />
                                <div v-if="canWriteTool">
                                    <q-btn
                                        label="Проверить учетные единицы"
                                        @click="checkTools"
                                    />
                                    <div>
                                        Всего учетных единиц:
                                    </div>
                                    <div>
                                        {{ toolsCheckResult.toolsCount }}
                                    </div>
                                    <div>
                                        Обновлены харастеристики у учетных единиц:
                                    </div>
                                    <div>
                                        {{ toolsCheckResult.toolsWithoutTraits }}
                                    </div>
                                    <div>
                                        Обновлены изображения у учетных единиц:
                                    </div>
                                    <div>
                                        {{ toolsCheckResult.toolsWithoutPictures }}
                                    </div>
                                </div>
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="details">
                            <!-- traits -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <q-select
                                    v-for="(toolKindTrait, index) in toolKindTraitsValues"
                                    :key="index"
                                    outlined
                                    dense
                                    :modelValue="toolKindTraitsValues[index].trait_value_id"
                                    :options="traitValues(toolKindTrait.tool_trait_id)"
                                    optionValue="value_id"
                                    optionLabel="value_title"
                                    mapOptions
                                    emitValue
                                    :label="toolKindTrait.title"
                                    class="q-mb-sm"
                                    @update:modelValue="(v) => traitValueChanged(
                                        toolKindTrait, index, v)"
                                />
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="docs">
                            <!-- documents -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <Documents
                                    fileSection="tool_docs"
                                    :filesList="filesListDocs"
                                    :addDocToList="addDoc"
                                    :deleteDoc="delDoc"
                                />
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="pictures">
                            <!-- pictures -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <Documents
                                    fileSection="tool_model_pictures"
                                    :filesList="filesListPictures"
                                    :addDocToList="addPicture"
                                    :deleteDoc="delPicture"
                                />
                            </q-card-section>
                        </q-tab-panel>
                    </q-tab-panels>

                    <q-separator />

                    <q-card-actions align="right">
                        <q-btn
                            flat
                            label="Сохранить"
                            color="primary"
                            :icon="matSave"
                            @click="onOkClick"
                        />
                        <q-btn
                            flat
                            label="Отменить"
                            color="primary"
                            :icon="matCancel"
                            @click="onCancelClick"
                        />
                    </q-card-actions>
                </div>
            </div>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import DialogEditSimple from "@/components/Dialogs/DialogEditSimple.vue";
import Documents from "@/components/Documents/Documents.vue";
import { RIGHTS_CONSTANTS } from "@/store/constants";

const defaultToolModel = {
    id: null,
    tool_kind_id: null,
    manufacturer_id: null,
    model: null,
    is_verified: false,
};

export default {
    name: "DialogEditToolModel",
    components: {
        Documents,
    },
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            showSpinner: false,
            toolModel: {},
            tabCurrent: "info",
            toolKindTraitsValues: [],
            filesListDocs: [],
            filesListPictures: [],
            slide: 1,
            toolsCheckResult: {},
        };
    },
    computed: {
        ...mapGetters({
            toolsKinds: "toolsKinds",
            manufacturers: "manufacturers",
            getTraitValues: "getTraitValues",
            currentUser: "currentUser",
        }),
        canWriteTool() {
            return !!(this.currentUser.rightsObject.RIGHT_TOOLS_WRITE & RIGHTS_CONSTANTS.FULL);
        },
    },
    watch: {
        "toolModel.tool_kind_id": function tmtki(value) {
            if (value) {
                this.getTraitsList();
            }
        },
    },
    methods: {
        checkErrorsInfo() {
            const hasErrors = Object.keys(this.$refs).reduce(
                (acc, key) => {
                    if (Object.prototype.hasOwnProperty.call(this.$refs[key], "validate")) {
                        this.$refs[key].validate();
                        return acc || this.$refs[key].hasError;
                    }
                    return acc;
                },
                false,
            );

            return hasErrors;
        },
        getTraitsList() {
            this.$store
                .dispatch(
                    "getToolKindTraitsValues",
                    {
                        toolId: this.toolModel.id ? this.toolModel.id : null,
                        kindId: this.toolModel.tool_kind_id
                            ? this.toolModel.tool_kind_id
                            : null,
                    },
                )
                .then((res) => {
                    this.toolKindTraitsValues = res;
                })
                .catch(() => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: "Ошибка",
                    });
                });
        },
        traitValues(toolTraitId) {
            return [
                ...this.getTraitValues(toolTraitId),
                {
                    value_id: -1,
                    value_title: "Добавить значение",
                },
            ];
        },
        traitValueChanged(toolKindTrait, index, valueId) {
            if (valueId === -1) {
                this.$q.dialog({
                    component: DialogEditSimple,
                    componentProps: {
                        dialogTitle: "Новое значение параметра",
                        itemForEdit: "",
                    },
                })
                    .onOk((value) => {
                        this.$store.dispatch("addToolKindTraitValue", {
                            traitId: toolKindTrait.tool_trait_id,
                            title: value,
                        })
                            .then((message) => {
                                this.$store.dispatch("getToolsKindsTraitsFromDB");
                                // TODO: set new param id to trait_value_id
                                this.$q.notify({
                                    type: "notify-success",
                                    message,
                                });
                            })
                            .catch((err) => {
                                this.$q.notify({
                                    type: "notify-failure",
                                    message: err,
                                });
                            });
                    });
            }
            else {
                this.toolKindTraitsValues[index].trait_value_id = valueId;
            }
        },
        addDoc(file) {
            this.filesListDocs.push(file);
        },
        delDoc(index) {
            this.filesListDocs.splice(index, 1);
        },
        addPicture(file) {
            this.filesListPictures.push(file);
        },
        delPicture(index) {
            this.filesListPictures.splice(index, 1);
            this.slide = 1;
        },
        tabChange(value) {
            if (this.$refs.refToolKind && this.checkErrorsInfo()) {
                this.tabCurrent = "info";
            }
            else {
                this.tabCurrent = value;
            }
        },
        checkTools() {
            this.showSpinner = true;
            const url = `${this.siteHost}/api/tools_models_fix`;

            axios.post(
                url,
                this.toolModel,
            )
                .then((res) => {
                    this.toolsCheckResult = res.data;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.showSpinner = false;
                });
        },
        //----------------------------------------------------------------------
        async show() {
            this.toolModel = {
                ...defaultToolModel,
                ...this.itemForEdit,
            };
            this.getTraitsList();
            this.tabCurrent = "info";

            if (this.itemForEdit.id) {
                this.filesListDocs = await this.$store.dispatch(
                    "getFilesListFromDB",
                    {
                        section: "tool_docs",
                        id: this.itemForEdit.id,
                    },
                );

                this.filesListPictures = await this.$store.dispatch(
                    "getFilesListFromDB",
                    {
                        section: "tool_model_pictures",
                        id: this.itemForEdit.id,
                    },
                );
            }
            else {
                this.filesListDocs = [];
                this.filesListPictures = [];
            }

            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            if (this.tabCurrent === "info" && this.checkErrorsInfo()) return;

            this.$emit(
                "ok",
                {
                    toolModel: this.toolModel,
                    toolKindTraitsValues: this.toolKindTraitsValues,
                    filesListDocs: this.filesListDocs,
                    filesListPictures: this.filesListPictures,
                },
            );

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
