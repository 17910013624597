<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Обновления ПО ГАС "Правосудие"
            <q-space />
            <FilterGasSoftRenewal
                v-model:dateBeg="filterDateBeg"
                v-model:dateEnd="filterDateEnd"
                v-model:programTitle="filterProgramTitle"
                v-model:programId="filterProgramId"
            />
        </div>
        <div class="content-body gas-soft-renewals-grid">
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Дата
            </div>
            <div class="cell cell-header cell-center">
                Наименование
            </div>
            <div class="cell cell-header cell-center">
                Описание
            </div>
            <div class="cell cell-header cell-center">
                Вид СПО
            </div>
            <div class="cell cell-header cell-center">
                Ссылки на скачивание файлов
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    v-if="canBeEdited"
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="startEdit(null)"
                />
            </div>

            <GasSoftRenewalRow
                v-for="(soft, index) in gasSoftRenwalsFilter"
                :key="index"
                :index="index"
                :soft="soft"
                :canBeEdited="canBeEdited"
                @startEdit="startEdit"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import FilterGasSoftRenewal from "@/components/Filters/FilterGasSoftRenewal.vue";
import DialogGasSoftRenewalEdit from "@/components/Dialogs/DialogGasSoftRenewalEdit.vue";
import GasSoftRenewalRow from "./GasSoftRenewalRow.vue";

export default {
    name: "GasSoftRenewal",
    components: {
        FilterGasSoftRenewal,
        GasSoftRenewalRow,
    },
    data() {
        return {
            filterDateBeg: null,
            filterDateEnd: null,
            filterProgramTitle: "",
            filterProgramId: null,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            gasSoftRenwals: "gasSoftRenwals",
        }),
        canBeEdited() {
            return this.currentUser.rightsObject.RIGHT_GAS_SOFT_RENEWAL_EDIT === RIGHTS_CONSTANTS.FULL;
        },
        gasSoftRenwalsFilter() {
            return this.gasSoftRenwals
                .filter((s) => (this.filterDateBeg ? s.date >= this.filterDateBeg : true))
                .filter((s) => (this.filterDateEnd ? s.date <= this.filterDateEnd : true))
                .filter((s) => (this.filterProgramTitle ? s.title.includes(this.filterProgramTitle) : true))
                .filter((s) => (this.filterProgramId ? s.program_id === this.filterProgramId : true));
        },
    },
    mounted() {
        this.$store.dispatch("getGasSoftRenewalsFromDB");
    },
    methods: {
        startEdit(index) {
            this.$q.dialog({
                component: DialogGasSoftRenewalEdit,
                componentProps: {
                    itemForEdit: index === null
                        ? {
                            id: null,
                            date: new Date(),
                            title: "",
                            description: "",
                            program_id: null,
                            gas_soft_renewals_files: [],
                        }
                        : this.gasSoftRenwals[index],
                },
            })
                .onOk((renewal) => {
                    this.$store.dispatch("saveGasSoftRenewal", renewal);
                });
        },
    },
};
</script>
