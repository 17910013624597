<template>
    <div style="overflow-y: auto; max-height: calc(100vh - 220px);">
        <div class="text-h6 flex q-pa-sm">
            {{ mItem.title }}
            <q-space />
        </div>
        <div class="q-pa-sm">
            {{ mItem.contents }}
        </div>
        <div
            v-for="mItemFile in mItem.manual_item_files"
            :key="mItemFile.id"
            style="display: flex; justify-content: center;"
        >
            <video
                v-if="mItemFile.mimetype.startsWith('video')"
                controls
                width="450"
            >
                <source
                    :src="`${siteHost}/${mItemFile.path}`"
                    :type="mItemFile.mimetype"
                >
            </video>
            <q-img
                v-else-if="mItemFile.mimetype.startsWith('image')"
                :src="`${siteHost}/${mItemFile.path}`"
                style="max-width: 300px;"
                spinnerColor="primary"
            />
            <div
                v-else
                style="color: blue; text-decoration: underline; cursor: pointer;"
                @click="saveFile(mItemFile)"
            >
                {{ mItemFile.file_name }}
            </div>
        </div>
    </div>
</template>

<script>
import { saveAs } from "file-saver";

export default {
    name: "ManualItem",
    props: {
        mItem: {
            type: Object,
            required: true,
        },
        canBeEdited: {
            type: Boolean,
            defalut: false,
        },
    },
    methods: {
        saveFile(f) {
            saveAs(`${this.siteHost}/${f.path}`, f.file_name);
        },
    },
};
</script>
