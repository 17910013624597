<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Обучающий материал
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-input
                    ref="refManualItemTitle"
                    v-model="manualItem.title"
                    outlined
                    dense
                    label="Наименование"
                    class="q-mb-sm"
                    :rules="[(val) => val && val.length > 0 || 'Укажите наименование']"
                    hideBottomSpace
                />
                <q-input
                    ref="refManualItemContents"
                    v-model="manualItem.contents"
                    outlined
                    dense
                    label="Описание"
                    class="q-mb-sm"
                    autogrow
                    :rules="[(val) => !val || (val && val.length < 2000) || 'Не более 2000 символов']"
                    hideBottomSpace
                />

                <Documents
                    fileSection="gasSoftRenewalFiles"
                    title="Файлы"
                    :filesList="manualItem.manual_item_files"
                    :addDocToList="addDoc"
                    :deleteDoc="delDoc"
                    :fileTypes="[
                        '.pdf',
                        '.doc',
                        '.docx',
                        '.odt',
                        '.xls',
                        '.xlsx',
                        '.zip',
                        '.rar',
                        '.7z',
                        '.txt',
                        '.avi',
                        '.mp4',
                        '.mkv',
                        '.jpg',
                        '.png',
                    ]"
                    :maxFileSize="262144000"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import Documents from "@/components/Documents/Documents.vue";

export default {
    name: "DialogManualItem",
    components: {
        Documents,
    },
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            manualItem: {},
            // filesList: [],
        };
    },
    methods: {
        show() {
            this.manualItem = { ...this.itemForEdit };
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        addDoc(file) {
            this.manualItem.manual_item_files.push(file);
        },
        delDoc(index) {
            this.manualItem.manual_item_files.splice(index, 1);
        },
        onOkClick() {
            this.$refs.refManualItemTitle.validate();
            this.$refs.refManualItemContents.validate();

            if (
                this.$refs.refManualItemTitle.hasError ||
                this.$refs.refManualItemContents.hasError
            ) {
                return;
            }

            this.$emit(
                "ok",
                this.manualItem,
                // {
                //     ...this.manualItem,
                //     // manual_item_files: this.filesList,
                // },
            );

            this.hide();
        },
    },
};
</script>
