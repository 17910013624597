<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Обновление ПО ГАС "Правосудие"
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <PoeDatePicker
                    v-model="softDate"
                    labelTitle="Дата"
                    :futureDateAllowed="false"
                />
                <q-input
                    ref="refProgramName"
                    v-model="softItem.title"
                    outlined
                    dense
                    label="Наименование"
                    class="q-mb-sm"
                    :rules="[(val) => val && val.length > 0 || 'Укажите наименование']"
                    hideBottomSpace
                />
                <q-input
                    ref="refProgramDescription"
                    v-model="softItem.description"
                    outlined
                    dense
                    label="Описание ПО"
                    class="q-mb-sm"
                    autogrow
                    :rules="[(val) => !val || (val && val.length < 2000) || 'Не более 2000 символов']"
                    hideBottomSpace
                />
                <q-select
                    ref="refProgramId"
                    v-model="softItem.program_id"
                    outlined
                    dense
                    :options="programsFilter"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Програмное обеспечение"
                    clearable
                    class="q-mb-sm"
                    :rules="[(val) => val || 'Нужно выбрать']"
                    hideBottomSpace
                />

                <Documents
                    fileSection="gasSoftRenewalFiles"
                    title="Файлы"
                    :filesList="filesList"
                    :addDocToList="addDoc"
                    :deleteDoc="delDoc"
                    :fileTypes="[
                        '.pdf',
                        '.doc',
                        '.docx',
                        '.odt',
                        '.xls',
                        '.xlsx',
                        '.zip',
                        '.rar',
                        '.7z',
                        '.exe',
                        '.txt',
                    ]"
                    :maxFileSize="262144000"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import Documents from "@/components/Documents/Documents.vue";
import { PROGRAM_TYPES_VALUES } from "@/store/constants";

export default {
    name: "DialogGasSoftRenewalEdit",
    components: {
        PoeDatePicker,
        Documents,
    },
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            softItem: {},
            filesList: [],
        };
    },
    computed: {
        ...mapGetters({
            programs: "programs",
        }),
        programsFilter() {
            return this.programs.filter((p) => p.type === PROGRAM_TYPES_VALUES.SOFT_SPECIAL);
        },
        softDate: {
            get() {
                return this.$dayjs(this.softItem.date).format("DD.MM.YYYY");
            },
            set(v) {
                this.softItem.date = this.$dayjs(v, "DD.MM.YYYY").toDate();
            },
        },
    },
    methods: {
        show() {
            this.softItem = { ...this.itemForEdit };
            this.filesList = [...this.itemForEdit.gas_soft_renewals_files];
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        addDoc(file) {
            this.filesList.push(file);
        },
        delDoc(index) {
            this.filesList.splice(index, 1);
        },
        onOkClick() {
            this.$refs.refProgramName.validate();
            this.$refs.refProgramDescription.validate();
            this.$refs.refProgramId.validate();

            if (
                this.$refs.refProgramName.hasError ||
                this.$refs.refProgramDescription.hasError ||
                this.$refs.refProgramId.hasError
            ) {
                return;
            }

            if (this.filesList.length === 0) {
                this.$q.notify({
                    type: "notify-failure",
                    message: "Нужно добавить файлы",
                });
                return;
            }

            this.$emit(
                "ok",
                {
                    ...this.softItem,
                    gas_soft_renewals_files: this.filesList,
                },
            );

            this.hide();
        },
    },
};
</script>
