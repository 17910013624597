<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Оборудование
            ({{ toolsCount }})
            <q-space />
            <SelectFilter
                v-model="regionId"
                :options="regions"
                label="Регион"
                clearable
                class="org-age-count--input"
            />
            <!-- <SelectFilter
                v-model="hostId"
                :options="hostsFiltered"
                optionLabel="name"
                label="Балансодержатель"
                clearable
                class="org-age-count--input"
            /> -->
            <SelectFilter
                v-model="unitId"
                :options="unitsFiltered"
                optionLabel="name"
                label="Объект"
                clearable
                class="org-age-count--input"
                extendedScope
                scopeAttr1="name"
                scopeAttr2="not_needed"
                scopeAttr3="address"
            />
        </div>
        <div class="content-body">
            <div class="tools-type-age-grid">
                <div class="cell cell-header cell-center">
                    №
                </div>
                <div class="cell cell-header cell-center">
                    Вид оборудования
                </div>
                <div class="cell cell-header cell-center">
                    Общее количество
                </div>
                <div class="cell cell-header cell-center">
                    Старше 5-ти лет
                </div>
                <div class="cell cell-header cell-center">
                    Младше 5-ти лет
                </div>
                <div
                    v-for="(ageCount, index) in toolsAgeCount"
                    :key="index"
                    class="row-wrapper"
                >
                    <div class="cell cell-center">
                        {{ index + 1 }}
                    </div>
                    <div class="cell">
                        {{ ageCount.tool_kind_title || "Вид оборудования не указан" }}
                    </div>
                    <div class="cell cell-center">
                        <q-btn
                            outline
                            :label="ageCount.count_all"
                            class="poeso-table--button"
                            @click="goToList(ageCount.tool_kind_id, null)"
                        />
                    </div>
                    <div class="cell cell-center">
                        <q-btn
                            outline
                            :label="ageCount.count_old"
                            class="poeso-table--button"
                            @click="goToList(ageCount.tool_kind_id, 'count_old')"
                        />
                    </div>
                    <div class="cell cell-center">
                        <q-btn
                            outline
                            :label="ageCount.count_all - ageCount.count_old"
                            class="poeso-table--button"
                            @click="goToList(ageCount.tool_kind_id, 'count_new')"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        v-if="showSpinner"
        class="req-dialog-cover"
    >
        <q-spinner-grid
            color="primary"
            size="5.5em"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import SelectFilter from "@/components/Common/SelectFilter.vue";

export default {
    name: "ToolsAgeCount",
    components: {
        SelectFilter,
    },
    data() {
        return {
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            unitsFull: "unitsFull",
            hosts: "hosts",
            toolsAgeCountFilter: "toolsAgeCountFilter",
            toolsAgeCount: "toolsAgeCount",
            currentUser: "currentUser",
        }),
        hostsFiltered() {
            return this.regionId
                ? this.hosts.filter((u) => u.region_id === this.regionId)
                : this.hosts;
        },
        unitsFiltered() {
            let units = !!(this.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.ALL)
                ? this.unitsFull
                : this.unitsFull.filter((u) => this.currentUser.units.includes(u.id));

            units = this.regionId
                ? units.filter((u) => u.region_id === this.regionId)
                : units;

            units = this.hostId
                ? units.filter((u) => u.host_id === this.hostId)
                : units;

            return units;
        },
        regionId: {
            get() {
                return this.toolsAgeCountFilter.region_id;
            },
            set(regionId) {
                this.$store.dispatch(
                    "setToolsAgeCountFilter",
                    {
                        ...this.toolsAgeCountFilter,
                        region_id: regionId,
                        host_id: null,
                        unit_id: null,
                    },
                );
            },
        },
        hostId: {
            get() {
                return this.toolsAgeCountFilter.host_id;
            },
            set(hostId) {
                this.$store.dispatch(
                    "setToolsAgeCountFilter",
                    {
                        ...this.toolsAgeCountFilter,
                        host_id: hostId,
                        unit_id: null,
                    },
                );
            },
        },
        unitId: {
            get() {
                return this.toolsAgeCountFilter.unit_id;
            },
            set(unitId) {
                this.$store.dispatch(
                    "setToolsAgeCountFilter",
                    {
                        ...this.toolsAgeCountFilter,
                        unit_id: unitId,
                    },
                );
            },
        },
        toolsCount() {
            return this.toolsAgeCount.reduce((acc, t) => acc + +t.count_all, 0);
        },
    },
    mounted() {
        this.showSpinner = true;
        this.$store.dispatch("getToolsAgeCountFromDB")
            .then(() => {
                this.showSpinner = false;
            });
    },
    methods: {
        goToList(tool_kind_id, toolsAge) {
            this.$store.dispatch(
                "setToolsFilter",
                {
                    toolsFilter: {
                        region_id: this.regionId,
                        serial_exact: false,
                        inventar_exact: false,
                        tool_model_exact: false,
                        tool_in_set: false,
                        tool_work_in_dt_from: null,
                        tool_work_in_dt_till: null,
                        tool_state: null,
                        tool_description: "",
                        tool_age: toolsAge,
                        tool_kind_ids: [tool_kind_id || 0],
                        units_ids: this.toolsAgeCountFilter.unit_id
                            ? [this.toolsAgeCountFilter.unit_id]
                            : [],
                    },
                },
            );

            this.$router.push("/tools");
        },
    },
};
</script>
